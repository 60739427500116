import styled from 'styled-components';

export const CameraGraphContainer = styled.div`
  grid-area: camera-graph;
  background-color: #c6c6c6;
  padding: 0 10px 0px 10px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 4px 8px 7px rgba(0, 0, 0, 0.3);
`;
