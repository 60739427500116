const reverseString = (str) => {
  return str.split('').reverse().join('');
};

/**
 * Check if email and password is valid during user creation
 * @param {string, object} email, password object to be checked
 * @return {string} error message or '' if valid
 */
export const validateChangePassword = (
  email,
  { oldPassword, newPassword1, newPassword2 },
) => {
  if (oldPassword.length < 1) return 'PASSWORD_EMPTY';
  if (newPassword1 !== newPassword2) return 'NEW_PASSWORD_NOT_MATCH';
  return checkPasswordValidity(email, newPassword1);
};

/**
 * Check if email and password is valid during user creation
 * @param {string, string} email, password string to be checked
 * @return {string} error message or '' if valid
 */
export const validateUserCreation = (email, password) => {
  if (password.length < 1 || email.length < 1) return 'EMAIL_PASSWORD_EMPTY';
  // test for valid email address
  let emailRegex = /^[-@.+_A-Za-z0-9]+@[-@.+_A-Za-z0-9]+\.[A-Za-z]{2,}$/;
  if (email.length > 50 || !emailRegex.test(email))
    return 'EMAIL_VALIDATE_FAILURE';
  return checkPasswordValidity(email, password);
};

/**
 * Check if password is valid during user creation
 * @param {string, string} email, password string to be checked
 * @return {string} error message or '' if valid
 */
export const checkPasswordValidity = (email, password) => {
  // password length 8-256
  if (password.length < 8 || password.length > 256) {
    return 'PASSWORD_VALIDATE_FAILURE';
  }
  // same / reverse of username
  if (email === password || reverseString(email) === password) {
    return 'PASSWORD_VALIDATE_FAILURE';
  }

  let numMatch = 0;
  let uppercaseRegex = /^(?=.*[A-Z]).{8,256}$/;
  let lowercaseRegex = /^(?=.*[a-z]).{8,256}$/;
  let numberRegex = /^(?=.*\d).{8,256}$/;
  if (uppercaseRegex.test(password)) numMatch++;
  if (lowercaseRegex.test(password)) numMatch++;
  if (numberRegex.test(password)) numMatch++;
  if (numMatch < 3) {
    return 'PASSWORD_VALIDATE_FAILURE';
  }
  // prevent input of double-byte characters
  let halfwidthRegex = /^[-@.+_a-zA-Z0-9]{8,256}$/;

  if (!halfwidthRegex.test(password))
    return 'PASSWORD_VALIDATE_FAILURE';

  return '';
};

/**
 * Map error message to appropriate japanese counterpart
 * @param {string} error string to be checked
 * @return {string} error message or '' if valid
 */
export const errorMessageUtil = (errorMessage) => {
  const userMessageTypes = {
    CREATE_USER_SUCCESS: 'ユーザを追加しました。',
    SUCCESS: 'パスワードを変更しました。',
    'User does not exist.':
      '※メールアドレスあるいはパスワードが正しくありません。',
    'Password attempts exceeded': 'パスワード試行回数を超えました。',
    'Attempt limit exceeded, please try after some time.':
      '試行回数を超えました。しばらくしてからもう一度お試しください。',
    'Incorrect username or password.':
      '※メールアドレスあるいはパスワードが正しくありません。',
    'An account with the given email already exists.':
      'そのメールアドレスは既に登録済みです。',
    'User already exists': 'そのメールアドレスは既に登録済みです。',
    'Username cannot be empty': 'メールアドレスを入力してください。',
    EMAIL_PASSWORD_EMPTY: 'メールアドレス、パスワードを入力してください。',
    EMAIL_VALIDATE_FAILURE:
      '※メールアドレスは50文字以下の半角英数とし、@ . + - _以外の文字を含まないでください。',
    PASSWORD_VALIDATE_FAILURE:
      'パスワードは8文字以上、256文字以下とし、英大文字、英小文字、半角数字を含めてください。',
    NEW_PASSWORD_NOT_MATCH: '新しいパスワードが一致しません。',
  };

  return userMessageTypes[errorMessage]
    ? userMessageTypes[errorMessage]
    : 'エラー発生、もう一度試してください.';
};
