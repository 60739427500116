import { createSelector } from 'reselect';

const selectWeather = (state) => state.weather;

export const selectAllWeather = createSelector(
  [selectWeather],
  (weather) => weather.weatherList,
);

export const selectIsWeatherFetching = createSelector(
  [selectWeather],
  (weather) => weather.isFetching,
);

export const selectFetchTimestamp = createSelector(
  [selectWeather],
  (weather) =>
    weather.fetchTimestamp ? weather.fetchTimestamp : '-',
);

export const selectIsWeatherLoaded = createSelector(
  [selectAllWeather],
  (weather) => {
    return weather.length !== 0 ? true : false;
  },
);
