import { takeLatest, call, put, all } from 'redux-saga/effects';
import moment from 'moment';
import { fetchWrapperWithCognitoToken } from '../utils/utils';

import PictureActionTypes from './picture.types';

import {
  fetchPictureSuccess,
  fetchPictureFailure,
} from './picture.actions';

function* fetchPictureAsync({ cameraIdList, whichPage, timeArray }) {
  try {
    let pictureList = {};
    if (whichPage === 'CAMERA-ALL') {

      const pictureRequests = yield cameraIdList.map( (id) => {
        return fetchWrapperWithCognitoToken(
          `https://${process.env.REACT_APP_API_DOMAIN}/infratector/api/` +
          `pictures/?camera=${id}&limit=1&ordering=-taken_at`
        );
      });
      const pictureResponses = yield Promise.all(pictureRequests);

      for (let response of pictureResponses) {
        if (response.status !== 200)
          continue;
        const pictureData = yield response.json();
        if (pictureData.results.length) 
            pictureList[pictureData.results[0]['camera']] = [pictureData.results[0]];
      }

    } else if (whichPage === 'CAMERA-DETAIL') {
      const pictureData = yield call(
        fetchWrapperWithCognitoToken,
        `https://${process.env.REACT_APP_API_DOMAIN}/infratector/api/pictures/?` +
        `camera=${cameraIdList[0]}&limit=1000` +
        `&taken_at__gte=${timeArray[0]}&taken_at__lte=${timeArray[1]}` +
        `&ordering=-taken_at`,
      );
      if (pictureData.status === 200) {
        const pictureDataParsed = yield pictureData.json();
        if (pictureDataParsed.results[0]) {
          pictureList[cameraIdList[0]] = pictureDataParsed.results
        }
      } else {
        throw new Error(pictureData.status);
      }
    }
    yield put(
      fetchPictureSuccess(
        pictureList,
        moment().format('YYYY/MM/DD HH:mm:ss'),
      ),
    );
  } catch (error) {
    yield put(fetchPictureFailure(error));
  }
}

function* fetchPictureStart() {
  yield takeLatest(
    PictureActionTypes.FETCH_PICTURE_START,
    fetchPictureAsync,
  );
}

export function* rootPictureSaga() {
  yield all([call(fetchPictureStart)]);
}
