import React from 'react';

import { FooterContainer } from './footer.styles';

const Footer = () => {
  return (
    <FooterContainer>
      Copyright©NTT COMWARE CORPORATION
    </FooterContainer>
  );
};

export default Footer;
