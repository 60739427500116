import { combineReducers } from 'redux';

import cameraReducer from './camera/camera.reducer';
import userReducer from './user/user.reducer';
import pictureReducer from './picture/picture.reducer';
import waterlevelReducer from './waterlevel/waterlevel.reducer';
import weatherReducer from './weather/weather.reducer';
import gaugeareaReducer from './gaugearea/gaugearea.reducer';
import weatherareasReducer from './weatherareas/weatherareasSlice';

export default combineReducers({
  camera: cameraReducer,
  user: userReducer,
  picture: pictureReducer,
  waterlevel: waterlevelReducer,
  weather: weatherReducer,
  gaugearea: gaugeareaReducer,
  weatherareas: weatherareasReducer,
});
