import React, { useState, useEffect } from 'react';
import { getCurrentUserMunicipalitiesUserList } from '../../admin-utils.js';
import MaterialTable from '@material-table/core';
import { TABLE_ICONS } from './user-table.settings';

import { DeleteRounded } from '@mui/icons-material';
import { UserTableContainer } from './user-table.styles';
import Spinner from '../spinner/spinner.component';

const UserTable = ({ handleDelete }) => {

    const [userList, setUserList] = useState([]);

    useEffect( () => {
        getCurrentUserMunicipalitiesUserList()
            .then( (municipalitiesUserList) => {
                setUserList(municipalitiesUserList);
            });
    }, [userList.length]);

    const createDataObj = (itemsArr) => {
        return itemsArr.map((item, idx) => ({
            id: item.Username,
            enabled: item.Enabled,
            accstatus: item.UserStatus,
            email:
            item.Attributes[
                item.Attributes.findIndex(
                    (element) => element.Name === 'email',
                )
            ].Value,
            updated: new Date(item.UserLastModifiedDate).toLocaleString(
                'ja-JP',
            ),
            created: new Date(item.UserCreateDate).toLocaleString('ja-JP'),
        }));
    };

    if (userList.length === 0) 
        return <Spinner />

    return (
        <UserTableContainer>
        <MaterialTable
        icons={TABLE_ICONS}
        style={{
            boxShadow: '4px 8px 7px rgba(0, 0, 0, 0.3)',
        }}
        title="ユーザ⼀覧"
        columns={[
            {
                title: 'Username',
                field: 'id',
                cellStyle: {
                    fontSize: '11px',
                },
            },
            {
                title: 'Enabled',
                field: 'enabled',
            },
            {
                title: 'Account status',
                field: 'accstatus',
                cellStyle: {
                    fontSize: '12px',
                },
            },
            {
                title: 'Email',
                field: 'email',
                cellStyle: {
                    fontSize: '16px',
                },
            },
            {
                title: 'Updated',
                field: 'updated',
                cellStyle: {
                    fontSize: '13px',
                },
            },
            {
                title: 'Created',
                field: 'created',
                cellStyle: {
                    fontSize: '13px',
                },
            },
        ]}
        data={createDataObj(userList)}
        options={{
            headerStyle: {
                backgroundColor: '#007871',
                    color: '#FFF',
            },
                padding: 'dense',
                minBodyHeight: '400px',
                maxBodyHeight: '400px',
                actionsColumnIndex: -1,
        }}
        localization={{
            header: { actions: '' },
        }}
        actions={[
            {
                icon: () => <DeleteRounded style={{ color: 'grey' }} />,
                tooltip: 'Delete User',
                onClick: (_, rowData) => {
                    handleDelete(rowData);
                },
            },
        ]}
        />
        </UserTableContainer>
    );
};

export default UserTable;
