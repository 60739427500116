import { takeLatest, call, put, all } from 'redux-saga/effects';
import moment from 'moment';
import { fetchWrapperWithCognitoToken } from '../utils/utils';

import WeatherActionTypes from './weather.types';

import {
  fetchWeatherSuccess,
  fetchWeatherFailure,
} from './weather.actions';

function* fetchWeatherAsync({ weatherareaId, timeArray }) {
  try {
    let weatherList = [];
    //   // fetchWrapperWithCognitoToken and parse /weather API
    const weatherData = yield call(
      fetchWrapperWithCognitoToken,
      `https://${process.env.REACT_APP_API_DOMAIN}/infratector/api/weatherforecast/?` +
      `weatherarea=${weatherareaId}&limit=1000&ordering=condition_date&` +
      `condition_date__gte=${timeArray[0]}&condition_date__lte=${timeArray[1]}`,
    );
    if (weatherData.status === 200) {
      const weatherDataParsed = yield weatherData.json();
      weatherList = weatherDataParsed.results;
      weatherList.forEach(
        (weather) =>
          (weather.rainfall = parseFloat(weather.rainfall)),
      );
    }
    yield put(
      fetchWeatherSuccess(
        weatherList,
        moment().format('YYYY/MM/DD HH:mm:ss'),
      ),
    );
  } catch (error) {
    yield put(fetchWeatherFailure(error));
  }
}

function* fetchWeatherStart() {
  yield takeLatest(
    WeatherActionTypes.FETCH_WEATHER_START,
    fetchWeatherAsync,
  );
}

export function* rootWeatherSaga() {
  yield all([call(fetchWeatherStart)]);
}
