import styled, { createGlobalStyle } from 'styled-components';

export const DatePickerWrapperStyles = createGlobalStyle`
    .date_picker {
      display: flex;
      align-items: center;
      align-self: center;
      width: 335px;
      height: 33px;
      font-size: 13px;
      border-radius: 4px;
      box-shadow: inset 0 2px 2px #e9e9e9;
      border: 1px solid #aeaeae;
      line-height: 16px;
      padding: 6px 10px 5px;
    }
`;

export const DateRangePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  justify-content: center;
`;

export const CustomButtonContainer = styled.div`
  justify-self: center;
`;
