import styled from 'styled-components';

export const LoginContainer = styled.div`
  width: 380px;
  display: flex;
  flex-direction: column;
  margin: 5px auto;
`;

export const LoginTitle = styled.h2`
  margin: 10px 50px;
  display: flex;
  justify-content: center;
`;

export const ErrorMessageContainer = styled.span`
  color: red;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
