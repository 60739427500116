import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import Logout from '@mui/icons-material/Logout';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {
  selectCurrentUser,
  selectIsUserAdmin,
} from '../../redux/user/user.selectors';
import { signOutStart } from '../../redux/user/user.actions';

import UserManagementDialog from '../user-management-dialog/user-management-dialog.component';
import ChangePasswordDialog from '../change-password-dialog/change-password-dialog.component';

const UserMenu = () => {
  const dispatch = useDispatch();
  const currUser = useSelector(selectCurrentUser);
  const isUserAdmin = useSelector(selectIsUserAdmin);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPassDialog, setOpenPassDialog] = useState(false);
  const [openManageDialog, setOpenManageDialog] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    // IE before 11 does not support
    // const { value } = event.currentTarget.dataset;
    const value = event.currentTarget.getAttribute('data-value');
    setAnchorEl(null);
    if (value === 'USER_MENU_ITEM') setOpenManageDialog(true);
    if (value === 'PASSWORD_CHANGE_MENU_ITEM')
      setOpenPassDialog(true);
    if (value === 'LOGOUT_MENU_ITEM') dispatch(signOutStart());
  };
  return (
    <React.Fragment>
      <Button
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        <PersonIcon fontSize="medium" />
        {currUser.attributes.email}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {isUserAdmin ? (
          <MenuItem
            onClick={handleClose}
            data-value={'USER_MENU_ITEM'}
          >
            <GroupIcon /> ユーザ管理
          </MenuItem>
        ) : null}
        <MenuItem
          onClick={handleClose}
          data-value={'PASSWORD_CHANGE_MENU_ITEM'}
        >
          <VpnKeyIcon /> パスワード変更
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={handleClose}
          data-value={'LOGOUT_MENU_ITEM'}
        >
          <Logout />
          ログアウト
        </MenuItem>
      </Menu>
      {isUserAdmin ? (
        <UserManagementDialog
          {...{ openManageDialog, setOpenManageDialog }}
        />
      ) : null}
      <ChangePasswordDialog
        {...{ openPassDialog, setOpenPassDialog }}
      />
    </React.Fragment>
  );
};

export default UserMenu;
