import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';

import { emailSignInStart } from '../../redux/user/user.actions';
import { selectErrorMessage } from '../../redux/user/user.selectors';
import { selectIsUserLoading } from '../../redux/user/user.selectors';

import {
  LoginContainer,
  ButtonContainer,
  LoginTitle,
  ErrorMessageContainer,
} from './login.styles';

const Login = () => {
  const [userCredentials, setCredentials] = useState({
    email: '',
    password: '',
  });
  const dispatch = useDispatch();
  const errorMessage = useSelector(selectErrorMessage);
  const isUserLoading = useSelector(selectIsUserLoading);

  const { email, password } = userCredentials;

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(emailSignInStart({ email, password }));
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setCredentials({ ...userCredentials, [name]: value });
  };

  return (
    <LoginContainer>
      <LoginTitle>ログイン</LoginTitle>
      {errorMessage ? (
        <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
      ) : null}
      <form onSubmit={handleSubmit}>
        <FormInput
          name="email"
          type="email"
          value={email}
          handleChange={handleChange}
          label="メールアドレスを入力してください"
          required
        />
        <FormInput
          name="password"
          type="password"
          value={password}
          handleChange={handleChange}
          label="パスワードを入力してください"
          required
        />
        <ButtonContainer>
          <CustomButton
            type="submit"
            value="Submit Form"
            disabled={isUserLoading}
          >
            ログイン
          </CustomButton>
        </ButtonContainer>
      </form>
    </LoginContainer>
  );
};

export default Login;
