import { createSelector } from 'reselect';

const selectGaugearea = (state) => state.gaugearea;

export const selectCurrentGaugearea = createSelector(
  [selectGaugearea],
  (gaugearea) => gaugearea.gaugeareaList[0],
);

export const selectGaugeareaFetching = createSelector(
  [selectGaugearea],
  (gaugearea) => gaugearea.isFetching,
);

export const selectFetchTimestamp = createSelector(
  [selectGaugearea],
  (gaugearea) =>
    gaugearea.fetchTimestamp ? gaugearea.fetchTimestamp : '-',
);

export const selectIsGaugeareaLoaded = createSelector(
  [selectCurrentGaugearea],
  (gaugearea) => {
    return gaugearea.length !== 0 ? true : false;
  },
);
