import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { selectIsUserLoading } from '../../redux/user/user.selectors';
import WithSpinner from '../with-spinner/with-spinner.component';
import UserTable from './user-table.component';

const mapStateToProps = createStructuredSelector({
  isLoading: (state) => selectIsUserLoading(state),
});

const UserTableHOC = compose(
  connect(mapStateToProps),
  WithSpinner,
)(UserTable);

export default UserTableHOC;
