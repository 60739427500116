import { Storage } from 'aws-amplify';

function blobToBase64(blob) {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}

export function extractObjectKeyFromS3Link(s3Link) {
    const s3URL = new URL(s3Link);
    return s3URL.pathname.split('/')[2];
}

export async function getAuthorizedBase64(objectKey) {
    const storageOptions = {
        download: true,
    };
    const s3Response = await Storage.get(objectKey, storageOptions);
    const base64Object = await blobToBase64(s3Response.Body);

    return base64Object;
}
