import CameraActionTypes from './camera.types';

export const fetchCameraStart = (whichPage, offset) => ({
  type: CameraActionTypes.FETCH_CAMERA_START,
  offset: offset,
  whichPage: whichPage,
});

export const fetchCameraSuccess = (cameraData, numOfPages) => ({
  type: CameraActionTypes.FETCH_CAMERA_SUCCESS,
  payload: cameraData,
  numOfPages: numOfPages,
});

export const fetchCameraFailure = (errorMessage) => ({
  type: CameraActionTypes.FETCH_CAMERA_FAILURE,
  payload: errorMessage,
});

export const modifyCameraStart = (currCameraItem) => ({
  type: CameraActionTypes.MODIFY_CAMERA_START,
  currCameraItem: currCameraItem,
});

export const modifyCameraSuccess = () => ({
  type: CameraActionTypes.MODIFY_CAMERA_SUCCESS,
});

export const modifyCameraFailure = (errorMessage) => ({
  type: CameraActionTypes.MODIFY_CAMERA_FAILURE,
  payload: errorMessage,
});

export const removeCameraStart = (currCameraItem) => ({
  type: CameraActionTypes.REMOVE_CAMERA_START,
  currCameraItem: currCameraItem,
});

export const removeCameraSuccess = () => ({
  type: CameraActionTypes.REMOVE_CAMERA_SUCCESS,
});

export const removeCameraFailure = (errorMessage) => ({
  type: CameraActionTypes.REMOVE_CAMERA_FAILURE,
  payload: errorMessage,
});

export const createCameraStart = (currCameraItem) => ({
  type: CameraActionTypes.CREATE_CAMERA_START,
  currCameraItem: currCameraItem,
});

export const createCameraSuccess = () => ({
  type: CameraActionTypes.CREATE_CAMERA_SUCCESS,
});

export const createCameraFailure = (errorMessage) => ({
  type: CameraActionTypes.CREATE_CAMERA_FAILURE,
  payload: errorMessage,
});
