import { useState, useEffect } from 'react';
import Spinner from '../spinner/spinner.component';
import { getAuthorizedBase64 } from './utils';

export const AuthorizedS3Image = ({ imageKey, altInfo }) => {
    const [image64, setImage64] = useState(null);

    useEffect( () => {
        getAuthorizedBase64(imageKey)
            .then( (base64Image) => {
                setImage64(base64Image);
            });
    }, [imageKey]);

    if (image64 === null)
        return <Spinner />

    return (
        <img
            src = { image64 }
            alt = { altInfo }
            style = {{
                aspectRatio: '16/9',
                objectFit: 'contain',
                width: '100%',
            }}
        ></img>
    );
}
