import PictureActionTypes from './picture.types';

const INITIAL_STATE = {
  isFetching: false,
  errorMessage: null,
  fetchTimestamp: null,
  pictureList: {},
};

const pictureReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PictureActionTypes.CLEAR_PICTURE_UNMOUNT:
      return {
        ...state,
        pictureList: {},
      };
    case PictureActionTypes.FETCH_PICTURE_START:
      return {
        ...state,
        isFetching: true,
      };
    case PictureActionTypes.FETCH_PICTURE_SUCCESS:
      return {
        ...state,
        pictureList: action.pictureList,
        fetchTimestamp: action.timestamp,
        isFetching: false,
      };
    case PictureActionTypes.FETCH_PICTURE_FAILURE:
      return {
        ...state,
        isFetching: false,
        fetchTimestamp: null,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default pictureReducer;
