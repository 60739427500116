import UserActionTypes from './user.types';

const INITIAL_STATE = {
  isLoading: false,
  checkUserSessionComplete: false,
  currentUser: null,
  userList: null,
  errorMessage: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.CHECK_USER_SESSION_COMPLETE:
      return {
        ...state,
        currentUser: action.payload,
        checkUserSessionComplete: true,
      };
    case UserActionTypes.CHECK_USER_SESSION_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        checkUserSessionComplete: true,
      };
    case UserActionTypes.EMAIL_SIGN_IN_START:
    case UserActionTypes.SIGN_OUT_START:
    case UserActionTypes.CHANGE_PASS_START:
    case UserActionTypes.FETCH_ALL_USER_START:
    case UserActionTypes.DELETE_USER_START:
    case UserActionTypes.SIGN_UP_START:
      return {
        ...state,
        isLoading: true,
      };
    case UserActionTypes.SIGN_UP_SUCCESS:
    case UserActionTypes.DELETE_USER_SUCCESS:
    case UserActionTypes.CHANGE_PASS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case UserActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        errorMessage: null,
        isLoading: false,
      };
    case UserActionTypes.SIGN_OUT_SUCCESS:
      return {
        ...state,
        currentUser: null,
        userList: null,
        errorMessage: null,
        isLoading: false,
      };
    case UserActionTypes.FETCH_ALL_USER_SUCCESS:
      return {
        ...state,
        userList: action.payload,
        isLoading: false,
      };
    case UserActionTypes.SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case UserActionTypes.SIGN_IN_FAILURE:
    case UserActionTypes.SIGN_OUT_FAILURE:
    case UserActionTypes.SIGN_UP_FAILURE:
    case UserActionTypes.CHANGE_PASS_FAILURE:
    case UserActionTypes.FETCH_ALL_USER_FAILURE:
    case UserActionTypes.DELETE_USER_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default userReducer;
