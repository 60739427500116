import React, { useState, useEffect } from 'react';

import { SpinnerContainer, SpinnerOverlay } from './spinner.styles';

const Spinner = ({stops}) => { 
    const [isOnScreen, setIsOnScreen] = useState(true);
    useEffect(() => {
      if (!stops)
        return;
      const timer = setTimeout(() => setIsOnScreen(false), stops);
      return (() => clearTimeout(timer));
    }, [stops]);

    if (!isOnScreen)
        return <div />;

    return (
      <SpinnerOverlay>
        <SpinnerContainer/>
      </SpinnerOverlay>
    );
};

export default Spinner;
