import {
  takeLatest,
  call,
  put,
  all,
  select,
} from 'redux-saga/effects';
import { Auth, API } from 'aws-amplify';
import toast from 'react-hot-toast';

import UserActionTypes from './user.types';
import { errorMessageUtil } from './user.utils';

import {
  signInSuccess,
  signInFailure,
  signUpSuccess,
  signUpFailure,
  signOutSuccess,
  signOutFailure,
  changePassSuccess,
  changePassFailure,
  setErrorMessage,
  fetchAllUserSuccess,
  fetchAllUserFailure,
  deleteUserSuccess,
  deleteUserFailure,
  checkUserSessionComplete,
  checkUserSessionFailure,
} from './user.actions';

import { selectCurrentUser } from './user.selectors';

function* isUserAuthenticated() {
  try {
    const user = yield Auth.currentAuthenticatedUser({
      bypassCache: false,
    });
    yield put(checkUserSessionComplete(user));
  } catch (error) {
    yield put(checkUserSessionFailure(error.message || null));
  }
}

function* signOut() {
  try {
    yield Auth.signOut();
    yield put(signOutSuccess());
  } catch (error) {
    yield put(signOutFailure(error.message));
  }
}

function* signInWithEmail({ payload: { email, password } }) {
  try {
    const user = yield Auth.signIn(email, password);
    yield put(signInSuccess(user));
  } catch (error) {
    yield put(signInFailure(error.message));
  }
}

function* changePassStart({
  payload: { oldPassword, newPassword1 },
}) {
  const user = yield select(selectCurrentUser);
  const toastId = toast.loading(
    `ユーザ${user.attributes.email}パスワード変更中...`,
  );
  try {
    const data = yield Auth.changePassword(
      user,
      oldPassword,
      newPassword1,
    );
    yield toast.success(
      `ユーザ${user.attributes.email}パスワード変更成功`,
      { id: toastId },
    );
    yield put(setErrorMessage(data));
    yield put(changePassSuccess());
  } catch (error) {
    yield put(changePassFailure(error.message));
    yield toast.error(
      `ユーザパスワード変更失敗: ${errorMessageUtil(error.message)}`,
      {
        id: toastId,
      },
    );
  }
}

function* createUser({
  payload: { username, password, userAttributes },
}) {
  const apiName = 'AdminQueries';
  const path = '/createUser';
  const toastId = toast.loading(`ユーザ${username}追加中...`);

  try {
    const userSession = yield Auth.currentSession();
    const userToken = yield userSession
      .getAccessToken()
      .getJwtToken();

    let groupnameList = [];
    const cognitoGroups = yield userSession.getAccessToken().payload['cognito:groups'];
    if (cognitoGroups.length !== 0) {
      groupnameList = yield cognitoGroups.filter((groupname) => !isNaN(Number(groupname)));
    }

    const myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: userToken,
      },
      body: {
        username,
        groupnameList,
        MessageAction: 'SUPPRESS',
        TemporaryPassword: password,
        userAttributes,
      },
    };
    const user = yield API.post(apiName, path, myInit);
    yield toast.success(`ユーザ${username}追加成功`, { id: toastId });
    yield put(setErrorMessage('CREATE_USER_SUCCESS'));
    yield put(signUpSuccess(user));
  } catch (error) {
    yield put(signUpFailure(error.response.data.message));
    yield toast.error(
      `ユーザ追加失敗: ${errorMessageUtil(
        error.response.data.message,
      )}`,
      {
        id: toastId,
      },
    );
  }
}

function* deleteUser({ payload: username }) {
  const apiName = 'AdminQueries';
  const path = '/deleteUser';
  const toastId = toast.loading(`ユーザ${username}削除中...`);
  try {
    const userSession = yield Auth.currentSession();
    const userToken = yield userSession
      .getAccessToken()
      .getJwtToken();
    const myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: userToken,
      },
      body: {
        username,
      },
    };
    yield API.post(apiName, path, myInit);
    toast.success(`ユーザ${username}削除成功`, { id: toastId });
    yield put(deleteUserSuccess());
  } catch (error) {
    yield put(deleteUserFailure(error.response.data.message));
    yield toast.error(
      `ユーザ削除失敗: ${error.response.data.message}`,
      {
        id: toastId,
      },
    );
  }
}

function* getAllUsers() {
  const apiName = 'AdminQueries';
  const path = '/listUsers';
  try {
    const userSession = yield Auth.currentSession();
    const userToken = yield userSession
      .getAccessToken()
      .getJwtToken();
    const myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: userToken,
      },
    };
    const userList = yield API.get(apiName, path, myInit);
    yield put(fetchAllUserSuccess(userList));
  } catch (error) {
    yield put(fetchAllUserFailure(error.response.data.message));
  }
}

function* onCheckUserSession() {
  yield takeLatest(
    UserActionTypes.CHECK_USER_SESSION,
    isUserAuthenticated,
  );
}

function* onSignOutStart() {
  yield takeLatest(UserActionTypes.SIGN_OUT_START, signOut);
}

function* onSignUpStart() {
  yield takeLatest(UserActionTypes.SIGN_UP_START, createUser);
}

function* onEmailSignInStart() {
  yield takeLatest(
    UserActionTypes.EMAIL_SIGN_IN_START,
    signInWithEmail,
  );
}

function* onChangePassStart() {
  yield takeLatest(
    UserActionTypes.CHANGE_PASS_START,
    changePassStart,
  );
}

function* onDeleteUserStart() {
  yield takeLatest(UserActionTypes.DELETE_USER_START, deleteUser);
}

export function* rootUserSaga() {
  yield all([
    call(onCheckUserSession),
    call(onSignOutStart),
    call(onSignUpStart),
    call(onEmailSignInStart),
    call(onChangePassStart),
    call(onDeleteUserStart),
  ]);
}
