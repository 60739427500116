import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import Spinner from '../../components/spinner/spinner.component';

import {
  selectCurrentUser,
  selectIsCheckUserSessionComplete,
} from '../../redux/user/user.selectors';

export const RequireAuth = ({ children, loginRoute }) => {
  const currentUser = useSelector(selectCurrentUser);
  const userLoading = useSelector(selectIsCheckUserSessionComplete);
  // if checkUserSession has not finished, return spinner
  if (userLoading) {
    return <Spinner />;
  } else {
    // handling for if user trying access /login
    if (loginRoute) {
      return currentUser ? (
        <Navigate to="/camera-all" replace />
      ) : (
        children
      );
      // handling for all other routes
    } else {
      return currentUser ? (
        children
      ) : (
        <Navigate to="/login" replace />
      );
    }
  }
};
