import { createSelector } from 'reselect';
import GeoJSON from 'ol/format/GeoJSON';

import {
  selectLatestWaterLevel,
  selectAllLatestWaterLevel,
} from '../waterlevel/waterlevel.selectors';

const selectCamera = (state) => state.camera;

const geojsonConvertUtil = (features) => {
  const wktOptions = {
    dataProjection: 'EPSG:4326',
    featureProjection: 'EPSG:3857',
  };
  let transformedFeatures = [];
  // read feature by feature in order to set extra fields
  features['features'].forEach((feature) => {
    const createdFeature = new GeoJSON().readFeature(
      feature,
      wktOptions,
    );
    createdFeature.set('threshold_lv1', feature.level_thresh1);
    createdFeature.set('threshold_lv2', feature.level_thresh2);
    // set water level from API source in future
    createdFeature.set('water_lvl', feature.water_lvl);
    transformedFeatures.push(createdFeature);
  });
  return transformedFeatures;
};

export const selectCameraItems = createSelector(
  [selectCamera],
  (camera) => camera.cameraData,
);

export const selectNumOfCameraPages = createSelector(
  [selectCamera],
  (camera) => camera.numOfPages,
);

export const selectIdList = createSelector(
  [selectCameraItems],
  (cameraItems) => {
    if (cameraItems.length !== 0) {
      const itemList = cameraItems.map((item) => {
        return item.id.toString();
      });
      return itemList;
    } else {
      return [];
    }
  },
);

export const selectCameraItem = (cameraIdParam) =>
  createSelector([selectCameraItems], (cameraItems) => {
    if (cameraIdParam) {
      const extractedFeature = cameraItems.find(
        (item) => item.id === parseInt(cameraIdParam),
      );
      if (extractedFeature) {
        return extractedFeature;
      } else {
        // temporary
        console.log('Invalid camera!');
      }
    }
  });

export const selectCameraNameById = (cameraIdParam) =>
  createSelector([selectCameraItems], (cameraItems) => {
    if (cameraIdParam) {
      const extractedFeature = cameraItems.find(
        (item) => item.id === parseInt(cameraIdParam),
      );
      if (extractedFeature) {
        return extractedFeature.name;
      } else {
        // temporary
        console.log('Invalid camera!');
      }
    }
  });

export const selectCameraItemsTransformed = (
  cameraIdParam,
  currPage,
) =>
  createSelector(
    [
      selectCameraItems,
      selectLatestWaterLevel(cameraIdParam),
      selectAllLatestWaterLevel,
    ],
    (cameraItems, latestWaterlevel, latestWaterLevelList) => {
      cameraIdParam = parseInt(cameraIdParam);
      if (cameraIdParam) {
        const extractedFeature = cameraItems.find(
          (item) => item.id === cameraIdParam,
        );
        if (extractedFeature) {
          return geojsonConvertUtil({
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [
                    extractedFeature.lon,
                    extractedFeature.lat,
                  ],
                },
                cameraId: extractedFeature.id,
                id: extractedFeature.name,
                // level_thresh1: parseFloat(
                //   extractedFeature.level_thresh1,
                // ),
                // level_thresh2: parseFloat(
                //   extractedFeature.level_thresh2,
                // ),
                // water_lvl: latestWaterlevel.length
                //   ? parseFloat(latestWaterlevel[0]['level_judge'])
                //   : 0,
              },
            ],
          });
        } else {
          // temporary
          console.log('Invalid camera!');
        }
      } else {
        const transformed = [];
        cameraItems
          .slice(currPage * 5, (currPage + 1) * 5)
          .forEach((item) => {
            transformed.push({
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [item.lon, item.lat],
              },
              cameraId: item.id,
              id: item.name,
              level_thresh1: parseFloat(item.level_thresh1),
              level_thresh2: parseFloat(item.level_thresh2),
              water_lvl: latestWaterLevelList[item.id]
                ? latestWaterLevelList[item.id].length
                  ? parseFloat(
                      latestWaterLevelList[item.id][0]['level_judge'],
                    )
                  : 0
                : 0,
            });
          });
        return geojsonConvertUtil({
          type: 'FeatureCollection',
          features: transformed,
        });
      }
    },
  );

export const selectFirstCameraCoord = createSelector(
  [selectCameraItems],
  (cameraItems) => {
    return cameraItems.length
      ? [cameraItems[0].lon, cameraItems[0].lat]
      : [];
  },
);

export const selectIsCameraLoading = createSelector(
  [selectCamera],
  (camera) => camera.isLoading,
);

export const selectIsCameraLoaded = createSelector(
  [selectCameraItems],
  (camera) => {
    return camera.length !== 0 ? true : false;
  },
);
