import CameraActionTypes from './camera.types';

const INITIAL_STATE = {
  cameraData: [],
  isLoading: false,
  errorMessage: null,
  numOfPages: 1,
};

const cameraReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CameraActionTypes.FETCH_CAMERA_START:
      return {
        ...state,
        isLoading: true,
        cameraData: [],
      };
    case CameraActionTypes.FETCH_CAMERA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cameraData: action.payload,
        numOfPages: action.numOfPages,
      };
    case CameraActionTypes.FETCH_CAMERA_FAILURE:
    case CameraActionTypes.MODIFY_CAMERA_FAILURE:
    case CameraActionTypes.REMOVE_CAMERA_FAILURE:
    case CameraActionTypes.CREATE_CAMERA_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    case CameraActionTypes.MODIFY_CAMERA_START:
    case CameraActionTypes.REMOVE_CAMERA_START:
    case CameraActionTypes.CREATE_CAMERA_START:
      return {
        ...state,
        isLoading: true,
      };
    default:
      return state;
  }
};

export default cameraReducer;
