import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import { selectIsUserLoading } from '../../redux/user/user.selectors';

import { deleteUserStart } from '../../redux/user/user.actions';

import UserTableHOC from '../user-table/user-table.container';
import ConfirmationDialog from '../confirmation-dialog/confirmation-dialog.component';
import CreateUserDialog from '../create-user-dialog/create-user-dialog.component';

const UserManagementDialog = ({
  openManageDialog,
  setOpenManageDialog,
}) => {
  const dispatch = useDispatch();
  const isUserLoading = useSelector(selectIsUserLoading);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState('');

  // handle closing of current User Management dialog
  const handleCloseUserManageDialog = () => {
    setOpenManageDialog(false);
  };

  // handle DELETE USER confirmation dialog
  const handleCloseConfirmDialog = (e) => {
    if (e.target.id === 'YES_BUTTON')
      dispatch(deleteUserStart(userToDelete));
    setOpenConfirmDialog(false);
  };

  // handle DELETE USER UserTable delete button click
  const handleDelete = (userData) => {
    setOpenConfirmDialog(true);
    setUserToDelete(userData.email);
  };

  // handle "ユーザ追加メニュー" button click
  const handleClick = () => {
    setOpenUserDialog(true);
  };

  return (
    <React.Fragment>
      <Dialog
        open={openManageDialog}
        onClose={handleCloseUserManageDialog}
        // fullWidth={true}
        // maxWidth="lg"
        maxWidth="false"
      >
        <div style={{ width: 1100 }}>
          <DialogTitle
            sx={{
              backgroundColor: '#3bb6a9',
              color: 'white',
              fontWeight: '530',
            }}
          >
            ユーザ管理
            <IconButton
              aria-label="close"
              onClick={handleCloseUserManageDialog}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'white',
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              height: '550px',
              backgroundColor: '#E5E7E9',
            }}
          >
            <div style={{ paddingTop: '10px' }}>
              <UserTableHOC {...{ handleDelete }} />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClick} disabled={isUserLoading}>
              ユーザ追加メニュー
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <ConfirmationDialog
        {...{
          openConfirmDialog,
          handleCloseConfirmDialog,
          isUserLoading,
        }}
      />
      <CreateUserDialog {...{ openUserDialog, setOpenUserDialog }} />
    </React.Fragment>
  );
};

export default UserManagementDialog;
