import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ConfirmationDialog = ({
  openConfirmDialog,
  handleCloseConfirmDialog,
  isUserLoading,
}) => {
  return (
    <Dialog
      open={openConfirmDialog}
      onClose={handleCloseConfirmDialog}
    >
      <DialogTitle>ユーザを削除しますか?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          ユーザを削除しますか? 削除操作を元に戻すことはできません.
          続行しますか？
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseConfirmDialog} id={'NO_BUTTON'}>
          いいえ
        </Button>
        <Button
          onClick={handleCloseConfirmDialog}
          disabled={isUserLoading}
          id={'YES_BUTTON'}
          autoFocus
        >
          はい
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
