import { createSelector } from 'reselect';

import { errorMessageUtil } from './user.utils';

const selectUser = (state) => state.user;

export const selectCurrentUser = createSelector(
  [selectUser],
  (user) => user.currentUser,
);

export const selectUserList = createSelector(
  [selectUser],
  (user) => user.userList,
);

export const selectAccessToken = createSelector(
  [selectCurrentUser],
  (currUser) => currUser.signInUserSession.accessToken,
);

export const selectIdToken = createSelector(
  [selectCurrentUser],
  (currUser) =>
    currUser ? currUser.signInUserSession.idToken : null,
);

export const selectIsUserAdmin = createSelector(
  [selectIdToken],
  (idToken) => {
    if (idToken) {
      const groupList = idToken.payload['cognito:groups'];
      return groupList ? groupList.includes('ADMIN') : false;
    }
    return false;
  },
);

export const selectErrorMessage = createSelector(
  [selectUser],
  (user) => {
    if (user.errorMessage) {
      return errorMessageUtil(user.errorMessage);
    }
    return null;
  },
);

export const selectIsUserLoading = createSelector(
  [selectUser],
  (user) => user.isLoading,
);

export const selectIsCheckUserSessionComplete = createSelector(
  [selectUser],
  (user) => !user.checkUserSessionComplete,
);
