import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import {
    selectAllWaterlevel
} from '../../redux/waterlevel/waterlevel.selectors';

import { selectCameraItem } from '../../redux/camera/camera.selectors';

import { selectCurrentGaugearea } from '../../redux/gaugearea/gaugearea.selectors';

import {
    CrossSectionalMainContainer,
    CrossSectionalTopContainer,
    CrossSectionalMidContainer,
    CrossSectionalBottomContainer,
    CrossSectionalLv1Container,
    CrossSectionalLv2Container,
    CrossSectionalLabelContainer,
    CrossSectionalDetailsContainer,
} from './cross-sectional-view.styles';

const CrossSectionalView = ({ cameraId }) => {
    // fetch threshold data from cameras
    const cameraData = useSelector(selectCameraItem(cameraId));
    const waterlevelData = useSelector(selectAllWaterlevel);
    const latestWaterlevel = waterlevelData.findLast(
        (waterlevel) => waterlevel.level_status === "20"
    );
    const gaugeData = useSelector(selectCurrentGaugearea);
    const heightTop = gaugeData
        ? parseFloat(gaugeData['height_top'])
        : 10;
    const heightBottom = gaugeData
        ? parseFloat(gaugeData['height_bottom'])
        : 0;
    const denominator = heightTop - heightBottom;
    const lv1Processed = cameraData
        ? parseFloat(cameraData.level_thresh1) - heightBottom
        : 0;
    const lv2Processed = cameraData
        ? parseFloat(cameraData.level_thresh2) - heightBottom
        : 0;
    const waterProcessed = latestWaterlevel !== undefined
        ? parseFloat(latestWaterlevel['level_judge']) - heightBottom
        : 0;
    const threshLv1 =
        lv1Processed <= 0 ? 0 : lv1Processed / denominator;
    const threshLv2 =
        lv2Processed <= 0 ? 0 : lv2Processed / denominator;
    const waterLevelPixels =
        waterProcessed <= 0 ? 0 : waterProcessed / denominator;
    return (
        <CrossSectionalMainContainer>
            { latestWaterlevel !== undefined ? (<>
                    <CrossSectionalLabelContainer
                        waterLevel={
                            waterLevelPixels > 1 ? 
                                1 * 150 : waterLevelPixels * 150
                        }
                    > {
                        parseFloat(latestWaterlevel['level_judge'])
                            .toFixed(2)
                    }</CrossSectionalLabelContainer>
                    <CrossSectionalMidContainer
                        waterLevel={
                            waterLevelPixels > 1 ? 
                                1 * 150 : waterLevelPixels * 150
                        }
                    />
                </>)
                : null
            }
            <CrossSectionalLv1Container
                threshold={threshLv1 > 1 ? 0 : threshLv1 * 150}
            />
            <CrossSectionalLv2Container
                threshold={threshLv2 > 1 ? 0 : threshLv2 * 150}
            />
            <CrossSectionalTopContainer />
            <CrossSectionalBottomContainer />
            <CrossSectionalDetailsContainer>
                <span>最新の水位：</span>
                <span>{
                    latestWaterlevel !== undefined ? 
                        moment(latestWaterlevel.level_date)
                            .format('YYYY/MM/DD HH:mm:ss') : 'ー'
                }</span>
            </CrossSectionalDetailsContainer>
        </CrossSectionalMainContainer>
    );
};

export default CrossSectionalView;
