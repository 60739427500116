import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { selectIsCameraLoading } from '../../redux/camera/camera.selectors';
import WithSpinner from '../with-spinner/with-spinner.component';
import CameraTable from './camera-table.component';

const mapStateToProps = createStructuredSelector({
  isLoading: (state) => selectIsCameraLoading(state),
});

const CameraTableHOC = compose(
  connect(mapStateToProps),
  WithSpinner,
)(CameraTable);

export default CameraTableHOC;
