import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import UserMenu from '../user-menu/user-menu.component';

import { selectCurrentUser } from '../../redux/user/user.selectors';

import { HeaderContainer, LinkContainer } from './header.styles';

const Header = () => {
  const currUser = useSelector(selectCurrentUser);
  return (
    <HeaderContainer>
      <LinkContainer>
        <Link
          to="/camera-all"
          style={{ textDecoration: 'none', color: 'white' }}
        >
          河川監視システム
        </Link>
      </LinkContainer>
      {currUser ? <UserMenu /> : null}
    </HeaderContainer>
  );
};

export default Header;
