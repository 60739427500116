import WeatherActionTypes from './weather.types';

const INITIAL_STATE = {
  isFetching: false,
  errorMessage: null,
  fetchTimestamp: null,
  weatherList: [],
};

const weatherReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WeatherActionTypes.CLEAR_WEATHER_UNMOUNT:
      return {
        ...state,
        weatherList: [],
      };
    case WeatherActionTypes.FETCH_WEATHER_START:
      return {
        ...state,
        isFetching: true,
      };
    case WeatherActionTypes.FETCH_WEATHER_SUCCESS:
      return {
        ...state,
        weatherList: action.weatherList,
        fetchTimestamp: action.timestamp,
        isFetching: false,
      };
    case WeatherActionTypes.FETCH_WEATHER_FAILURE:
      return {
        ...state,
        isFetching: false,
        fetchTimestamp: null,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default weatherReducer;
