import PictureActionTypes from './picture.types';

export const fetchPictureStart = (
  cameraIdList,
  whichPage,
  timeArray,
) => ({
  type: PictureActionTypes.FETCH_PICTURE_START,
  cameraIdList: cameraIdList,
  whichPage: whichPage,
  timeArray: timeArray,
});

export const fetchPictureSuccess = (pictureList, timestamp) => ({
  type: PictureActionTypes.FETCH_PICTURE_SUCCESS,
  pictureList: pictureList,
  timestamp: timestamp,
});

export const fetchPictureFailure = (errorMessage) => ({
  type: PictureActionTypes.FETCH_PICTURE_FAILURE,
  payload: errorMessage,
});

export const clearPictureUnmount = () => ({
  type: PictureActionTypes.CLEAR_PICTURE_UNMOUNT,
});
