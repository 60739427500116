import { takeLatest, call, put, all } from 'redux-saga/effects';
import { fetchWrapperWithCognitoToken } from '../utils/utils';
import moment from 'moment';

import GaugeareaActionTypes from './gaugearea.types';

import {
  fetchGaugeareaSuccess,
  fetchGaugeareaFailure,
} from './gaugearea.actions';

function* fetchGaugeareaAsync({ cameraId }) {
  try {
    let gaugeareaList = [];
    //   // fetchWrapperWithCognitoToken and parse /gaugeareas API
    const gaugeareaData = yield call(
      fetchWrapperWithCognitoToken,
      `https://${process.env.REACT_APP_API_DOMAIN}/infratector/api/gaugeareas/?camera=${cameraId}&limit=1&ordering=-created_at`,
    );
    if (gaugeareaData.status === 200) {
      const gaugeareaDataParsed = yield gaugeareaData.json();
      gaugeareaList = gaugeareaDataParsed.results;
    }
    yield put(
      fetchGaugeareaSuccess(
        gaugeareaList,
        moment().format('YYYY/MM/DD HH:mm:ss'),
      ),
    );
  } catch (error) {
    yield put(fetchGaugeareaFailure(error));
  }
}

function* fetchGaugeareaStart() {
  yield takeLatest(
    GaugeareaActionTypes.FETCH_GAUGEAREA_START,
    fetchGaugeareaAsync,
  );
}

export function* rootGaugeareaSaga() {
  yield all([call(fetchGaugeareaStart)]);
}
