const WaterlevelActionTypes = {
  FETCH_WATERLEVEL_START: 'FETCH_WATERLEVEL_START',
  FETCH_WATERLEVEL_SUCCESS: 'FETCH_WATERLEVEL_SUCCESS',
  FETCH_WATERLEVEL_FAILURE: 'FETCH_WATERLEVEL_FAILURE',
  CLEAR_WATERLEVEL_UNMOUNT: 'CLEAR_WATERLEVEL_UNMOUNT',
  FETCH_LATEST_WATERLEVEL_START: 'FETCH_LATEST_WATERLEVEL_START',
  FETCH_LATEST_WATERLEVEL_SUCCESS: 'FETCH_LATEST_WATERLEVEL_SUCCESS',
};

export default WaterlevelActionTypes;
