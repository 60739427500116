import { takeLatest, call, put, all } from 'redux-saga/effects';
import moment from 'moment';
import { fetchWrapperWithCognitoToken } from '../utils/utils';

import WaterlevelActionTypes from './waterlevel.types';

import {
  fetchWaterlevelSuccess,
  fetchWaterlevelFailure,
  fetchLatestWaterlevelSuccess,
} from './waterlevel.actions';

function* fetchWaterlevelAsync({ cameraId, timeArray }) {
  try {
    let waterlevelList = [];
    let waterlevelPredURL, parsedPredResponse, predData;

    const waterlevelJudgeURL = new URL(`https://${process.env.REACT_APP_API_DOMAIN}/infratector/api/waterlevels/?`);
    waterlevelJudgeURL.searchParams.set('camera', cameraId);
    waterlevelJudgeURL.searchParams.set('limit', 1000);
    waterlevelJudgeURL.searchParams.set('ordering', '-level_date');
    waterlevelJudgeURL.searchParams.set('level_status', 20);
    waterlevelJudgeURL.searchParams.set('level_date__gte', timeArray[0]);

    const to_date = moment(timeArray[1]);
    if (to_date.isBefore()) {
      // if only looking for past data
      waterlevelJudgeURL.searchParams.set('level_date__lte', timeArray[1]);
    } else {
      waterlevelJudgeURL.searchParams.set('level_date__lte', moment()
        .format('YYYY-MM-DDTHH:mm'));

      waterlevelPredURL = new URL(waterlevelJudgeURL);
      waterlevelPredURL.searchParams.set('level_status', 10);
      waterlevelPredURL.searchParams.set('level_date__gte', moment()
        .add(1, 'second').format('YYYY-MM-DDTHH:mm'));
      waterlevelPredURL.searchParams.set('level_date__lte', timeArray[1]);

      const waterlevelPredResponse = yield call(
        fetchWrapperWithCognitoToken,
        waterlevelPredURL
      );
      parsedPredResponse = yield waterlevelPredResponse.json();
      predData = parsedPredResponse.results.reduce( (prev, curr) => {
        if (curr.level_pred === null)
          return prev;
        delete curr.level_judge;
        prev.push(curr)
        return prev;
      }, []);
      predData.reverse();
    }

    const waterlevelJudgeResponse = yield call(
      fetchWrapperWithCognitoToken,
      waterlevelJudgeURL
    );
    const parsedJudgeResponse = yield waterlevelJudgeResponse.json();
    const judgeData = parsedJudgeResponse.results.reduce( (prev, curr) => {
     if (curr.level_judge === null)
        return prev;
      delete curr.level_pred;
      prev.push(curr)
      return prev;
    }, []);
    judgeData.reverse();

    waterlevelList = judgeData.concat(waterlevelPredURL ? predData : []);

    // timestamps for graph display
    waterlevelList.forEach((waterlv) => {
      waterlv.timestamp = waterlv.level_date
        .split('T')[1]
        .slice(0, 5);
    });

    yield put(
      fetchWaterlevelSuccess(
        waterlevelList,
        moment().format('YYYY/MM/DD HH:mm:ss'),
      ),
    );
  } catch (error) {
    yield put(fetchWaterlevelFailure(error));
  }
}

function* fetchLatestWaterlevelAsync({ cameraIdList, timeArray }) {
  try {
    let waterlevelList = {};
    //   // fetchWrapperWithCognitoToken and parse /waterlevels API
    for (let cameraId of cameraIdList) {
      const waterlevelData = yield call(
        fetchWrapperWithCognitoToken,
        `https://${process.env.REACT_APP_API_DOMAIN}/infratector/api/waterlevels/?camera=${cameraId}&limit=1&level_status=20&ordering=-level_date&level_date__gte=${timeArray[0]}&level_date__lte=${timeArray[1]}`,
      );
      if (waterlevelData.status === 200) {
        let waterlevelDataParsed = yield waterlevelData.json();
        waterlevelDataParsed = waterlevelDataParsed.results;
        waterlevelDataParsed.forEach((waterlv) => {
          waterlv.timestamp = waterlv.level_date
            .split('T')[1]
            .slice(0, 5);
          waterlv.level_pred =
            waterlv.level_status === '10' ? waterlv.level_pred : null;
          waterlv.level_judge =
            waterlv.level_status === '20'
              ? waterlv.level_judge
              : null;
        });
        waterlevelList[cameraId] = waterlevelDataParsed;
      } else {
        waterlevelList[cameraId] = [];
      }
    }

    yield put(
      fetchLatestWaterlevelSuccess(
        waterlevelList,
        moment().format('YYYY/MM/DD HH:mm:ss'),
      ),
    );
  } catch (error) {
    yield put(fetchWaterlevelFailure(error));
  }
}

function* fetchWaterlevelStart() {
  yield takeLatest(
    WaterlevelActionTypes.FETCH_WATERLEVEL_START,
    fetchWaterlevelAsync,
  );
}

function* fetchLatestWaterlevelStart() {
  yield takeLatest(
    WaterlevelActionTypes.FETCH_LATEST_WATERLEVEL_START,
    fetchLatestWaterlevelAsync,
  );
}

export function* rootWaterlevelSaga() {
  yield all([
    call(fetchWaterlevelStart),
    call(fetchLatestWaterlevelStart),
  ]);
}
