const CameraActionTypes = {
  FETCH_CAMERA_START: 'FETCH_CAMERA_START',
  FETCH_CAMERA_SUCCESS: 'FETCH_CAMERA_SUCCESS',
  FETCH_CAMERA_FAILURE: 'FETCH_CAMERA_FAILURE',
  MODIFY_CAMERA_START: 'MODIFY_CAMERA_START',
  MODIFY_CAMERA_SUCCESS: 'MODIFY_CAMERA_SUCCESS',
  MODIFY_CAMERA_FAILURE: 'MODIFY_CAMERA_FAILURE',
  REMOVE_CAMERA_START: 'REMOVE_CAMERA_START',
  REMOVE_CAMERA_SUCCESS: 'REMOVE_CAMERA_SUCCESS',
  REMOVE_CAMERA_FAILURE: 'REMOVE_CAMERA_FAILURE',
  CREATE_CAMERA_START: 'CREATE_CAMERA_START',
  CREATE_CAMERA_SUCCESS: 'CREATE_CAMERA_SUCCESS',
  CREATE_CAMERA_FAILURE: 'CREATE_CAMERA_FAILURE',
};

export default CameraActionTypes;
