import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectCameraItem } from '../../redux/camera/camera.selectors';

import { TitleContainer } from './title-label.styles';

const TitleLabel = ({ title, cameraId, titleFor, style }) => {
  const preMemoFeatures = useMemo(() => selectCameraItem(cameraId), [
    cameraId,
  ]);
  const features = useSelector(preMemoFeatures);
  if (titleFor === 'camera-detail') {
    title = `${title}:  ${features.river} (${features.place})`;
  }

  return <TitleContainer {...{ titleFor, style }}>{title}</TitleContainer>;
};

export default TitleLabel;
