import WaterlevelActionTypes from './waterlevel.types';

export const fetchWaterlevelStart = (cameraId, timeArray) => ({
  type: WaterlevelActionTypes.FETCH_WATERLEVEL_START,
  cameraId: cameraId,
  timeArray: timeArray,
});

export const fetchWaterlevelSuccess = (
  waterlevelList,
  timestamp,
) => ({
  type: WaterlevelActionTypes.FETCH_WATERLEVEL_SUCCESS,
  waterlevelList: waterlevelList,
  timestamp: timestamp,
});

export const fetchLatestWaterlevelStart = (
  cameraIdList,
  timeArray,
) => ({
  type: WaterlevelActionTypes.FETCH_LATEST_WATERLEVEL_START,
  cameraIdList: cameraIdList,
  timeArray: timeArray,
});

export const fetchLatestWaterlevelSuccess = (
  latestWaterlevel,
  timestamp,
) => ({
  type: WaterlevelActionTypes.FETCH_LATEST_WATERLEVEL_SUCCESS,
  latestWaterlevel: latestWaterlevel,
  timestamp: timestamp,
});

export const fetchWaterlevelFailure = (errorMessage) => ({
  type: WaterlevelActionTypes.FETCH_WATERLEVEL_FAILURE,
  payload: errorMessage,
});

export const clearWaterlevelUnmount = () => ({
  type: WaterlevelActionTypes.CLEAR_WATERLEVEL_UNMOUNT,
});
