import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@mui/material';

const DialogComponent = ({ open, handleClose, deleteDialog }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">
        {deleteDialog
          ? '選択したカメラを削除します。 よろしいですか？'
          : 'カメラIDが変更されています。カメラ情報を変更しますか？'}
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} color="primary" value="no">
          いいえ
        </Button>
        <Button
          onClick={handleClose}
          color="primary"
          value={deleteDialog ? 'DELETE' : 'MODIFY'}
          autoFocus
        >
          はい
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogComponent;
