import React from 'react';
import { useSelector } from 'react-redux';

import Spinner from '../spinner/spinner.component';
import CameraGraph from './camera-graph.component';

import { selectIsCameraLoaded } from '../../redux/camera/camera.selectors';
import { selectIsWaterlevelFetching } from '../../redux/waterlevel/waterlevel.selectors';
import { selectIsWeatherFetching } from '../../redux/weather/weather.selectors';
import { selectGaugeareaFetching } from '../../redux/gaugearea/gaugearea.selectors';

const CameraGraphHOC = ({ ...props }) => {
  const isCameraLoaded = useSelector(selectIsCameraLoaded);
  const isWaterFetching = useSelector(selectIsWaterlevelFetching);
  const isWeatherFetching = useSelector(selectIsWeatherFetching);
  const isGaugeareaFetching = useSelector(selectGaugeareaFetching);

  return !isCameraLoaded ||
    isWaterFetching ||
    isWeatherFetching ||
    isGaugeareaFetching ? (
    <Spinner />
  ) : (
    <CameraGraph {...props} />
  );
};

export default CameraGraphHOC;
