import styled from 'styled-components';

export const HeaderContainer = styled.div`
  /* margin: 0px 20px 15px 5px; */
  display: flex;
  justify-content: center;
  background-color: #3bb6a9;
  margin-bottom: 5px;
  /* border: 1px solid black; */
  font-size: 1.5em;
  font-weight: 530;
  text-decoration: none;
  box-shadow: 0 6px 7px rgba(0, 0, 0, 0.42);
`;

export const LinkContainer = styled.div`
  margin-left: 44vw;
  margin-right: auto;
`;
