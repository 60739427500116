import { Auth } from "aws-amplify";

export async function fetchWrapperWithCognitoToken(url, options = {}) {
    let sessionData = await Auth.currentSession().then(data => data);
    let editedURL = new URL(url);

    let municipalityGroup = sessionData.idToken.payload["cognito:groups"]
        .filter(groupname => !isNaN(Number(groupname)));
    if (municipalityGroup.length) 
        editedURL.searchParams.set('group', municipalityGroup[0]);

    const optionsWithToken = {
        ...options,
        headers: {
            ...options.headers,
            Authorization: sessionData.idToken.jwtToken
        }
    }
    
    const fetchResult = await fetch(
        editedURL,
        optionsWithToken
    )

    return fetchResult;
}
