import styled from 'styled-components';

export const CrossSectionalMainContainer = styled.div`
  grid-area: camera-cross-view;
  display: grid;
  grid-template-columns: 200px 165px;
  grid-template-rows: 165px;
`;

export const CrossSectionalLabelContainer = styled.div`
  grid-column: 1;
  grid-row: 1;
  width: 185px;
  height: 33px;
  margin-bottom: ${(props) =>
    props.waterLevel ? props.waterLevel + 15 + 'px' : '0px'};
  font-size: 25px;
  align-self: end;
  text-align: center;
  color: blue;
  z-index: 3;
`;

export const CrossSectionalLv1Container = styled.div`
  grid-column: 1;
  grid-row: 1;
  width: 185px;
  height: 0px; // 15-165
  margin-bottom: ${(props) =>
    props.threshold ? props.threshold + 15 + 'px' : '0px'};
  border-top: 2px dashed yellow;
  align-self: end;
  z-index: 2;
`;

export const CrossSectionalLv2Container = styled.div`
  grid-column: 1;
  grid-row: 1;
  width: 185px;
  height: 0px; // 15-165
  margin-bottom: ${(props) =>
    props.threshold ? props.threshold + 15 + 'px' : '0px'};
  border-top: 2px dashed red;
  align-self: end;
  z-index: 2;
`;

export const CrossSectionalTopContainer = styled.div`
  grid-column: 1;
  grid-row: 1;
  width: 185px;
  background-color: #605c5c;
  clip-path: polygon(
    0% 0%,
    10% 0%,
    25% 90%,
    75% 90%,
    90% 0%,
    100% 0%,
    100% 100%,
    0% 100%
  );
  z-index: 1;
`;

export const CrossSectionalMidContainer = styled.div`
  grid-column: 1;
  grid-row: 1;
  width: 185px;
  height: ${(props) =>
    props.waterLevel ? props.waterLevel + 15 + 'px' : '0px'};
  /* height: 105px; // 15-165 */
  background-color: #a0ccdc;
  align-self: end;
  z-index: 0;
`;

export const CrossSectionalBottomContainer = styled.div`
  grid-column: 1;
  grid-row: 1;
  width: 185px;
  background-color: #c0c0c0;
  z-index: -1;
`;

export const CrossSectionalDetailsContainer = styled.div`
  grid-column: 2;
  grid-row: 1;
  display: flex;
  flex-direction: column;
`;
