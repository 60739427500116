import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MaterialTable from '@material-table/core';

import {
  AddBox,
  ArrowUpward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from '@mui/icons-material';

import { selectCameraItems } from '../../redux/camera/camera.selectors';
import { selectAllLatestWaterLevel } from '../../redux/waterlevel/waterlevel.selectors';
import { oprStatusMap } from '../../redux/camera/camera.utils';

import { CameraTableContainer } from './camera-table.styles';

const CameraTable = ({ currPage, setCurrPage }) => {
  const TABLE_ICONS = {
    // tableIcons
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => (
      <DeleteOutline {...props} ref={ref} />
    )),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => (
      <SaveAlt {...props} ref={ref} />
    )),
    Filter: forwardRef((props, ref) => (
      <FilterList {...props} ref={ref} />
    )),
    FirstPage: forwardRef((props, ref) => (
      <FirstPage {...props} ref={ref} />
    )),
    LastPage: forwardRef((props, ref) => (
      <LastPage {...props} ref={ref} />
    )),
    NextPage: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => (
      <Clear {...props} ref={ref} />
    )),
    Search: forwardRef((props, ref) => (
      <Search {...props} ref={ref} />
    )),
    SortArrow: forwardRef((props, ref) => (
      <ArrowUpward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => (
      <ViewColumn {...props} ref={ref} />
    )),
  };

  const allCameraItems = useSelector(selectCameraItems);
  const latestWaterLevels = useSelector(selectAllLatestWaterLevel);
  const createDataObj = (itemsArr) => {
    return itemsArr.map((item, idx) => {
      const waterLv = latestWaterLevels[item.id]
        ? latestWaterLevels[item.id].length
          ? parseFloat(latestWaterLevels[item.id][0]['level_judge'])
          : 0
        : 0;
      return {
        cameraname: item.name,
        rivername: item.river ? item.river : '-',
        addr: item.place ? item.place : '-',
        oprstatus: oprStatusMap[item.camera_status]
          ? oprStatusMap[item.camera_status]
          : '-',
        waterlv:
          item.camera_status !== '0'
            ? '-'
            : item.level_thresh2 && waterLv >= item.level_thresh2
            ? `${item.level_thresh2}m超`
            : item.level_thresh1 && waterLv >= item.level_thresh1
            ? `${item.level_thresh1}m超`
            : '平常',
        level_thresh1: item.level_thresh1,
        level_thresh2: item.level_thresh2,
        redirect: item.id,
      };
    });
  };
  const redirectFunction = (rowData, outputStr) => (
    <Link
      to={`../camera-list/${rowData.redirect}`}
      style={{ color: 'black' }}
    >
      {outputStr}
    </Link>
  );

  return (
    <CameraTableContainer>
      <MaterialTable
        page={currPage}
        icons={TABLE_ICONS}
        style={{
          boxShadow: '4px 8px 7px rgba(0, 0, 0, 0.3)',
          gridArea: 'camera-table'
        }}
        title="河川監視カメラ⼀覧"
        columns={[
          {
            title: 'カメラ名',
            field: 'cameraname',
            cellStyle: {
              borderRight: '1px solid #e5e5e5',
              overflowWrap: 'break-word',
            },
            render: (rowData) =>
              redirectFunction(rowData, rowData.cameraname),
          },
          {
            title: '河川名',
            field: 'rivername',
            cellStyle: {
              borderRight: '1px solid #e5e5e5',
              overflowWrap: 'break-word',
            },
          },
          {
            title: '設置場所',
            field: 'addr',
            cellStyle: {
              borderRight: '1px solid #e5e5e5',
              overflowWrap: 'break-word',
            },
          },
          {
            title: '稼働状況',
            field: 'oprstatus',
            cellStyle: (oprStatus) => {
              return {
                backgroundColor:
                  oprStatus === '故障中'
                    ? 'red'
                    : oprStatus === 'メンテナンス中'
                    ? 'yellow'
                    : 'transparent',
                borderRight: '1px solid #e5e5e5',
              };
            },
          },
          {
            title: '水位',
            field: 'waterlv',
            cellStyle: (waterlv, rowData) => {
              return {
                backgroundColor:
                  waterlv === `${rowData.level_thresh2}m超`
                    ? 'red'
                    : waterlv === `${rowData.level_thresh1}m超`
                    ? 'yellow'
                    : 'transparent',
                borderRight: '1px solid #e5e5e5',
              };
            },
          },
        ]}
        data={createDataObj(allCameraItems)}
        onPageChange={(pageNum) => {
          setCurrPage(pageNum);
        }}
        options={{
          headerStyle: {
            backgroundColor: '#007871',
            color: '#FFF',
          },
          pageSize: 5,
          pageSizeOptions: [],
          padding: 'dense',
          sorting: false,
          draggable: false,
        }}
      />
    </CameraTableContainer>
  );
};

export default CameraTable;
