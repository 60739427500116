import { toast } from 'react-hot-toast';
import { fetchWrapperWithCognitoToken } from './redux/utils/utils';

const resources = {
    waterlevels: '水位',
    weatherforecast: '降水量',
};

const query_limit = 50000;

function createApiUrl(resource) {
    return new URL(
        `https://${process.env.REACT_APP_API_DOMAIN}/infratector/api/` +
        `${resource}/?`
    );
}

export function getWaterlevel(cameraId, extraOptions) {
    return getApi(
        'waterlevels',
        { camera: cameraId, limit: query_limit }
    );
}

export function getWeather(weatherareaId, extraOptions) {
    return getApi(
        'weatherforecast',
        { weatherarea: weatherareaId, limit: query_limit }
    );
}

export async function getWeatherArea(weatherareaId) {
    const result = await getApi(
        'weatherarea',
        {'id': weatherareaId}
    );

    if (result === undefined) {
        return {};
    }

    if (result instanceof Array && result.length === 0) {
        return {};
    }

    return result[0];
}

async function getApi(resource, options = {}) {

    const url = createApiUrl(resource);
    for (const [key, value] of Object.entries(options)) {
        url.searchParams.set(key, value);
    }

    try {

        const response = await fetchWrapperWithCognitoToken(url);
        const data = await response.json();

        if (typeof data.next === 'string') {
            const nextOffset = typeof options.offset === 'number' ? 
                options.offset + query_limit : query_limit;
            const nextData = await getApi(
                resource, {...options, offset: nextOffset}
            );

            return [
                ...data.results,
                ...nextData
            ];
        }

        return data.results;
    } catch(error) {

        console.error(error);
        toast.error(`${resources[resource]}データを取得できませんでした。`);

        return undefined;
    }
};
