import React from 'react';

import Login from '../../components/login/login.component';

import { LoginPageContainer } from './login-page.styles';

const LoginPage = () => (
  <LoginPageContainer>
    <Login />
  </LoginPageContainer>
);

export default LoginPage;
