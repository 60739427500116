import React from 'react';
import { useSelector } from 'react-redux';
import { selectCameraItems } from '../../redux/camera/camera.selectors';

import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

const CameraSelect = ({
  currCameraId,
  handleChangeDropdown,
}) => {
  const allCameraItems = useSelector(selectCameraItems);
  return (
    <TextField
      sx={{
        width: '320px',
        height: '56px',
        backgroundColor: '#fef9e7',
        borderRadius: '4px',
        gridArea: 'camera-selection',
      }}
      select
      name="id"
      id="select-id"
      label="カメラ選択"
      value={currCameraId}
      onChange={handleChangeDropdown}
    >
      {allCameraItems.map((camera) => (
        <MenuItem key={camera.id} value={camera.id.toString()}>
          {camera.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default CameraSelect;
