import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { validateUserCreation } from '../../redux/user/user.utils';

import {
  selectIsUserLoading,
  selectErrorMessage,
} from '../../redux/user/user.selectors';

import {
  signUpStart,
  setErrorMessage,
} from '../../redux/user/user.actions';

const CreateUserDialog = ({ openUserDialog, setOpenUserDialog }) => {
  const isUserLoading = useSelector(selectIsUserLoading);
  const errorMessage = useSelector(selectErrorMessage);
  const dispatch = useDispatch();

  const [fields, setFields] = useState({
    email: '',
    password: '',
  });

  // clear textfields upon successful user creation
  useEffect(() => {
    if (errorMessage === 'ユーザを追加しました。') {
      setFields({
        email: '',
        password: '',
      });
    }
  }, [errorMessage]);

  // handle the closing of current user dialog
  const handleClose = () => {
    dispatch(setErrorMessage(null));
    setOpenUserDialog(false);
  };

  // handle when user clicks "ユーザ追加" button
  const handleSubmit = () => {
    const errorText = validateUserCreation(
      fields.email,
      fields.password,
    );
    if (errorText === '') {
      dispatch(
        signUpStart({
          username: fields.email,
          password: fields.password,
          userAttributes: [],
        }),
      );
      return;
    }
    dispatch(setErrorMessage(errorText));
  };

  // update user textfield input
  const handleChange = (event) => {
    const { value, name } = event.target;
    setFields({ ...fields, [name]: value });
  };

  return (
    <Dialog open={openUserDialog} onClose={handleClose}>
      <DialogTitle>ユーザ追加メニュー</DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{
            color: `${
              errorMessage === 'ユーザを追加しました。'
                ? 'green'
                : 'red'
            }`,
          }}
        >
          {errorMessage}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="email"
          label="メールアドレス"
          type="email"
          name="email"
          value={fields.email}
          fullWidth
          variant="outlined"
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          id="password"
          label="パスワード"
          type="password"
          name="password"
          value={fields.password}
          fullWidth
          variant="outlined"
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} disabled={isUserLoading}>
          ユーザ追加
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateUserDialog;
