import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';

import Map from 'ol/Map';
import View from 'ol/View';
import Feature from 'ol/Feature';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Point from 'ol/geom/Point';
import OSM from 'ol/source/OSM';
import { transform } from 'ol/proj';
import GeoJSON from 'ol/format/GeoJSON';
// import { toStringXY } from 'ol/coordinate';
import { Fill, Style, Icon, Text } from 'ol/style';
import { Attribution, defaults as defaultControls } from 'ol/control';

import 'ol/ol.css';

// import CctvImage from '../../assets/cctv.png';
import PinImage from '../../assets/normal_pin.png';
import GreenCctvImage from '../../assets/analysis_green.png';
import OrangeCctvImage from '../../assets/analysis_orange.png';
import RedCctvImage from '../../assets/analysis_red.png';

import {
  selectCameraItem,
  selectCameraItemsTransformed,
  selectFirstCameraCoord,
} from '../../redux/camera/camera.selectors';

import {
  CameraMapContainer,
} from './camera-map.styles';

const CameraMap = ({
  url,
  handleGeoChange,
  className,
  currPage,
  currCameraItem,
}) => {
  const [map, setMap] = useState();
  const [featuresLayer, setFeaturesLayer] = useState();
  // const [selectedCoord, setSelectedCoord] = useState();
  const preMemoTransformedFeatures = useMemo(
    () => selectCameraItemsTransformed(url, currPage),
    [url, currPage],
  );
  const transformedFeatures = useSelector(preMemoTransformedFeatures);

  const preMemoFeatures = useMemo(() => selectCameraItem(url), [url]);
  const features = useSelector(preMemoFeatures);

  const firstCameraLocation = useSelector(selectFirstCameraCoord);
  const mapElement = useRef();
  const mapRef = useRef();
  mapRef.current = map;
  const featuresRef = useRef();
  featuresRef.current = featuresLayer;

  useEffect(() => {
    const attribution = new Attribution({
      collapsible: true,
    });

    const constants = {
      GreenCctv: new Icon({
        anchor: [0.5, 40],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
        src: GreenCctvImage,
        scale: 1,
      }),
      OrangeCctv: new Icon({
        anchor: [0.5, 40],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
        src: OrangeCctvImage,
        scale: 1,
      }),
      RedCctv: new Icon({
        anchor: [0.5, 40],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
        src: RedCctvImage,
        scale: 1,
      }),
      Pin: new Icon({
        anchor: [0.5, 40],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
        src: PinImage,
        scale: 0.9,
      }),
    };

    const styleFunction = function (feature, styleString) {
      switch (styleString) {
        case 'hover':
          return new Style({
            image:
              feature.get('water_lvl') >= feature.get('threshold_lv2')
                ? constants.RedCctv
                : feature.get('water_lvl') >=
                  feature.get('threshold_lv1')
                ? constants.OrangeCctv
                : constants.GreenCctv,
            text: new Text({
              font: '12px sans-serif',
              text: feature.getId(),
              fill: new Fill({
                color: 'white',
              }),
              backgroundFill: new Fill({
                color: '#107896',
              }),
              offsetY: -50,
              padding: [3, 3, 3, 3],
            }),
          });
        case 'pin':
          return new Style({
            image: constants.Pin,
          });
        default:
          return new Style({
            image:
              feature.get('water_lvl') >= feature.get('threshold_lv2')
                ? constants.RedCctv
                : feature.get('water_lvl') >=
                  feature.get('threshold_lv1')
                ? constants.OrangeCctv
                : constants.GreenCctv,
          });
      }
    };

    const generateFeature = () => {
      const wktOptions = {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857',
      };
      const newFeature = new GeoJSON().readFeature(
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [currCameraItem.lon, currCameraItem.lat],
          },
        },
        wktOptions,
      );
      newFeature.set('pin', true);
      newFeature.setStyle(styleFunction(newFeature, 'pin'));
      return newFeature;
    };
    let newFeatureList = [];
    if (currCameraItem && currCameraItem.lon && currCameraItem.lat) {
      if (features) {
        if (
          !(
            currCameraItem.lon === features.lon &&
            currCameraItem.lat === features.lat
          )
        ) {
          newFeatureList.push(...transformedFeatures);
          newFeatureList.push(generateFeature());
        } else {
          newFeatureList.push(...transformedFeatures);
        }
      } else {
        newFeatureList.push(generateFeature());
      }
    }

    const initialFeaturesLayer = new VectorLayer({
      source: new VectorSource({
        features: newFeatureList.length
          ? newFeatureList
          : transformedFeatures,
      }),
      style: styleFunction,
    });

    const initialMap = new Map({
      target: mapElement.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        initialFeaturesLayer,
      ],
      view: new View({
        projection: 'EPSG:3857',
        center: features
          ? transform(
              [
                features.lon === '' ? '15396693.358591374' : features.lon,
                features.lat === '' ? '3400060.443454751' : features.lat,
              ],
              'EPSG:4326',
              'EPSG:3857',
            )
          : firstCameraLocation.length
          ? transform(firstCameraLocation, 'EPSG:4326', 'EPSG:3857')
          : transform(
              ['137.6626534101813', '36.72163380053365'],
              'EPSG:4326',
              'EPSG:3857',
            ),
        zoom: features || firstCameraLocation.length ? 15 : 4.6,
        minZoom: 4.6,
      }),
      controls: defaultControls({ attribution: false }).extend([
        attribution,
      ]),
    });

    if (handleGeoChange) {
      initialMap.on('click', (event) => {
        const clickedCoord = mapRef.current.getCoordinateFromPixel(
          event.pixel,
        );
        // setSelectedCoord(transformedCoord);
        // makes sure only one pin exists at a time
        featuresRef.current
          .getSource()
          .getFeatures()
          .forEach((feature) => {
            if (feature.get('pin')) {
              featuresRef.current.getSource().removeFeature(feature);
            }
          });
        // creating the new pin
        let point = new Point(clickedCoord);
        let feature = new Feature(point);
        feature.set('pin', true);
        feature.setStyle(styleFunction(feature, 'pin'));
        featuresRef.current.getSource().addFeature(feature);

        // update the coordinates in parent component state
        handleGeoChange(
          transform(clickedCoord, 'EPSG:3857', 'EPSG:4326'),
        );
      });
    }

    initialMap.on('pointermove', (event) => {
      if (event.dragging) return;

      featuresRef.current
        .getSource()
        .getFeatures()
        .forEach((feature) => {
          if (!feature.get('pin')) {
            feature.setStyle(styleFunction);
          }
        });

      const pixel = mapRef.current.getEventPixel(event.originalEvent);
      mapRef.current.forEachFeatureAtPixel(pixel, (feature) => {
        if (!feature.get('pin')) {
          feature.setStyle(styleFunction(feature, 'hover'));
          return feature;
        }
      });
    });

    setMap(initialMap);
    setFeaturesLayer(initialFeaturesLayer);
    return () => initialMap.setTarget(undefined);
  }, [
    features,
    transformedFeatures,
    handleGeoChange,
    firstCameraLocation,
    url,
    currCameraItem,
  ]);

  return (
    <CameraMapContainer ref={mapElement}></CameraMapContainer>
  );
};

export default CameraMap;
