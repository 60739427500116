import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';

const selectPicture = (state) => state.picture;

export const selectImagesByCamera = (cameraIdParam) =>
  createSelector([selectPicture], (picture) => {

    const pictureList = picture.pictureList;

    if (cameraIdParam) {
      if (pictureList.hasOwnProperty(cameraIdParam)) {

        let extractedPictureList = pictureList[cameraIdParam];
        let transformedPictureList = [];

        for (let picture of extractedPictureList) {
            if (!(picture.image && picture.taken_at)) 
                continue;

            transformedPictureList.push({
                src: picture.image,
                taken_at: picture.taken_at,
            });
        }

        return transformedPictureList;
      } else {
        return [];
      }
    } else {
      console.log('Image data not found!');
    }
});

export const selectPictureById = (pictureId, cameraId) => 
  createSelector([selectPicture], (picture) => {
    const isPictureLoaded = useSelector(selectIsPictureLoaded);
    if (isPictureLoaded === false) {
      return null;
    }
    console.log(pictureId, cameraId, picture);
    const findResult = picture.pictureList[cameraId].find(
      (picture) => picture.id === pictureId
    );
    if (findResult === undefined) {
      return null;
    } else {
      return findResult;
    }
  });
  
export const selectLatestImagesByCamera = (cameraIdParam) =>
  createSelector([selectImagesByCamera(cameraIdParam)], (picture) => {
    return picture[0];
  });

export const selectAllCameraId = createSelector(
  [selectPicture],
  (picture) => Object.keys(picture.dataObj),
);

export const selectIsPictureFetching = createSelector(
  [selectPicture],
  (picture) => picture.isFetching,
);

export const selectFetchTimestamp = createSelector(
  [selectPicture],
  (picture) =>
    picture.fetchTimestamp ? picture.fetchTimestamp : '-',
);

export const selectIsPictureLoaded = createSelector(
  [selectPicture],
  (picture) => {
    return Object.keys(picture.pictureList).length !== 0 ? true : false;
  },
);
