import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { selectIsPictureFetching } from '../../redux/picture/picture.selectors';
import WithSpinner from '../with-spinner/with-spinner.component';
import CameraCarousel from './camera-carousel.component';

const mapStateToProps = createStructuredSelector({
  isLoading: (state) => selectIsPictureFetching(state),
});

const CameraCarouselHOC = compose(
  connect(mapStateToProps),
  WithSpinner,
)(CameraCarousel);

export default CameraCarouselHOC;
