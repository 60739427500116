const UserActionTypes = {
  EMAIL_SIGN_IN_START: 'EMAIL_SIGN_IN_START',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
  CHECK_USER_SESSION: 'CHECK_USER_SESSION',
  CHECK_USER_SESSION_COMPLETE: 'CHECK_USER_SESSION_COMPLETE',
  CHECK_USER_SESSION_FAILURE: 'CHECK_USER_SESSION_FAILURE',
  SIGN_OUT_START: 'SIGN_OUT_START',
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
  SIGN_OUT_FAILURE: 'SIGN_OUT_FAILURE',
  SIGN_UP_START: 'SIGN_UP_START',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  SIGN_UP_FAILURE: 'SIGN_UP_FAILURE',
  CHANGE_PASS_START: 'CHANGE_PASS_START',
  CHANGE_PASS_SUCCESS: 'CHANGE_PASS_SUCCESS',
  CHANGE_PASS_FAILURE: 'CHANGE_PASS_FAILURE',
  SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
  FETCH_ALL_USER_START: 'FETCH_ALL_USER_START',
  FETCH_ALL_USER_SUCCESS: 'FETCH_ALL_USER_SUCCESS',
  FETCH_ALL_USER_FAILURE: 'FETCH_ALL_USER_FAILURE',
  DELETE_USER_START: 'DELETE_USER_START',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',
};

export default UserActionTypes;
