import styled from 'styled-components';

export const CameraTableContainer = styled.div`
  grid-area: camera-table;
`;

export const TempHeader = styled.h2`
  position: relative;
  width: 90px;
`;
