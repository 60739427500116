// @ts-check
import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';

import { selectImagesByCamera } from '../../redux/picture/picture.selectors';

import { CameraCarouselContainer } from './camera-carousel.styles';
import Spinner from '../spinner/spinner.component';
import { getAuthorizedBase64 } from '../s3-authorized/utils';

import moment from 'moment';
import toast from 'react-hot-toast';

import './camera-carousel.css';

/** 
 * @typedef {Object} image 
 * @prop {string} src path to image at S3
 * @prop {string} taken_at when was image taken
 */

/**
 * An object holding image data under original index from imageData
 * @typedef {Object.<number, image>} indexedImages 
 */

/**
 * An object holding image components under original index from imageData
 * @typedef {Object.<number, JSX.Element>} renderedIndexedImages 
 */

/**
 * Component to show time label on image at bottom left corner
 * @param {Object} props
 * @param {string} props.timestamp
 * @returns {JSX.Element}
 */
const TimestampElement = ({timestamp}) => {
    let timestampText = "画像撮影日時：";
    timestampText += moment(timestamp).format('YYYY-MM-DD HH:mm');

    return (
        <span
            style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '280px',
                borderRadius: '8px 0px 0px 0px',
                padding: '2px 6px',
                background: 'white',
                textAlign: 'center',
            }}
        >
            { timestampText }
        </span>
    );
}

/**
 * Image component of carousel
 * @param {Object} props
 * @param {string} props.imageSrc
 * @param {string} props.imageAlt
 * @returns {JSX.Element}
 */
const CarouselImage = ({imageSrc, imageAlt}) => {
    return (
        <img
            src={imageSrc}
            alt={imageAlt}
            className='carouselImage'
        />
    );
}

/**
 * Component composition which will be shown as main display in carousel
 * @param {Object} props
 * @param {string} props.imageSrc
 * @param {string} props.imageAlt
 * @param {string} props.timestamp
 * @returns {JSX.Element}
 */
const CarouselImageWithTimestamp = ({imageSrc, imageAlt, timestamp}) => {
    return (
        <div
            className='imageWithTimestampContainer'
        > 
            <TimestampElement 
                timestamp={timestamp}
            />
            <CarouselImage 
                imageSrc={imageSrc}
                imageAlt={imageAlt}
            />
        </div>
    );
}

/** 
 * Main component describing carousel and its images
 * @param {Object} props
 * @param {number} props.cameraId
 * @param {number} props.currImageIndex
 * @param {React.Dispatch<number>} props.setCurrImageIndex
 * @returns {JSX.Element}
 */
const CameraCarousel = ({ cameraId, currImageIndex, setCurrImageIndex }) => {
    const preMemoImageData = useMemo(
        () => selectImagesByCamera(cameraId),
        [cameraId],
    );

    /** @type {image[]} */
    const imageData = useSelector(preMemoImageData);
    /** @type {[number, React.Dispatch<number>]} */
    const [carouselIndex, setCarouselIndex] = useState(0);
    /** @type {[renderedIndexedImages, React.Dispatch<renderedIndexedImages>]} */
    const [renderedImages, setRenderedImages] = useState({});
    /** @type {[renderedIndexedImages, React.Dispatch<renderedIndexedImages>]} */
    const [renderedThumbnails, setRenderedThumbnails] = useState({});
    /** @type {React.MutableRefObject<boolean[]>} */
    const imagesDownloadCheckList = useRef(Array(imageData.length).fill(false));

    useEffect(() => {
        return () => {
            setCurrImageIndex(0);
            setCarouselIndex(0);
        };
    }, [imageData]);

    useEffect(() => {
        const imagesToDownload = getImagesToDownload();
        if (Object.keys(imagesToDownload).length === 0) {
            setCarouselIndex(getCarouselIndex());
            return;
        }
        
        downloadImages(imagesToDownload);
    }, [currImageIndex]);

    useEffect(() => {
        setCarouselIndex(getCarouselIndex());
    }, [renderedImages]);

    const getCarouselIndex = () => {
        return Object.keys(renderedImages).findIndex(val => val === currImageIndex.toString());
    };

    /** @param {indexedImages} selectedImages */
    const downloadImages = async (selectedImages) => {
        /** @type {renderedIndexedImages} */
        let carouselImages = {};
        /** @type {renderedIndexedImages} */
        let carouselThumbnails = {};
        /** @type {Promise[]} */
        let imageRequests = [];

        for (let image of Object.values(selectedImages)) {
            imageRequests.push(getAuthorizedBase64(image.src));
        }

        const imageToastId = toast.loading("画像取得中…");
        const downloadedBase64Images = await Promise.all(imageRequests);
        const imageIndexes = Object.keys(selectedImages);
        for (let i = 0; i < imageIndexes.length; i++) {
            const index = imageIndexes[i];
            const base64Image = downloadedBase64Images[i];
            carouselImages[index] = (
                <CarouselImageWithTimestamp 
                    key={index}
                    timestamp={selectedImages[index].taken_at}
                    imageSrc={base64Image}
                    imageAlt={selectedImages[index].taken_at}
                />
            );
            carouselThumbnails[index] = (
                <CarouselImage 
                    key={index}
                    imageSrc={base64Image}
                    imageAlt={selectedImages[index].taken_at}
                />
            );
        }

        setRenderedImages({ ...renderedImages, ...carouselImages });
        setRenderedThumbnails({ ...renderedThumbnails, ...carouselThumbnails });

        toast.success("画像取得完了", { id: imageToastId });
    }

    const getImagesToDownload = () => {
        /** @type {indexedImages} */
        const imagesToDownload = {};

        const beforeAfterLoadCount = 15;
        /** @type {number} index describing the oldest image to download */
        let beforeIndex = currImageIndex - beforeAfterLoadCount;
        if (beforeIndex < 0)
            beforeIndex = 0;
        /** @type {number} index describing the newest image to download */
        let afterIndex = currImageIndex + beforeAfterLoadCount;
        if (afterIndex > imageData.length)
            afterIndex = imageData.length;

        let beforeToDownloadImagesCount = 0;
        for (let i = beforeIndex; i < currImageIndex; i++) {
            if (imagesDownloadCheckList.current[i] === true)
                continue;
            
            beforeToDownloadImagesCount += 1;
        }

        let afterToDownloadImagesCount = 0;
        for (let i = currImageIndex; i < afterIndex; i++) {
            if (imagesDownloadCheckList.current[i] === true) 
                continue;
            
            afterToDownloadImagesCount += 1;
        }

        const downloadCountThreshold = 8;
        if (
            beforeToDownloadImagesCount < downloadCountThreshold &&
            afterToDownloadImagesCount < downloadCountThreshold && 
            /* Only if data size is larger than doubled threshold */
            downloadCountThreshold * 2 < imageData.length
        )
            return imagesToDownload;

        for (let i = beforeIndex; i < afterIndex; i++) {
            if (imagesDownloadCheckList.current[i] === true)
                continue;

            imagesDownloadCheckList.current[i] = true;
            imagesToDownload[i] = imageData[i];
        }

        return imagesToDownload;
    };

    if (Array.isArray(imageData) === false || imageData.length === 0) {
        return (
            <div style={{
                maxWidth: '640px', maxHeight: '360px',
                width: '100%', height: '100%',
                backgroundColor: "black", color: "lightgray",
                gridArea: "camera-carousel"
            }} ></div>
        );
    }

    if (Object.values(renderedThumbnails).length === 0)
        return <Spinner />

    return (
        <CameraCarouselContainer>
            <Carousel
                index={carouselIndex}
                onIndexChange={({curIndex}) => {
                    setCarouselIndex(curIndex);
                    setCurrImageIndex(Number(Object.keys(renderedImages)[curIndex]));
                }}
                thumbnails={Object.values(renderedThumbnails)}
                hasIndexBoard={false}
                shouldMaximizeOnClick={true}
                shouldMinimizeOnClick={true}
                hasLeftButton='centerLeft'
                hasRightButton='centerRight'
                canAutoPlay={true}
                autoPlayInterval={2000}
                isRTL={true}
                isLoop={false}
            >
                {Object.values(renderedImages)}
            </Carousel>
        </CameraCarouselContainer>
    );
};

export default CameraCarousel;
