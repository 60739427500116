import GaugeareaActionTypes from './gaugearea.types';

export const fetchGaugeareaStart = (cameraId) => ({
  type: GaugeareaActionTypes.FETCH_GAUGEAREA_START,
  cameraId: cameraId,
});

export const fetchGaugeareaSuccess = (gaugeareaList, timestamp) => ({
  type: GaugeareaActionTypes.FETCH_GAUGEAREA_SUCCESS,
  gaugeareaList: gaugeareaList,
  timestamp: timestamp,
});

export const fetchGaugeareaFailure = (errorMessage) => ({
  type: GaugeareaActionTypes.FETCH_GAUGEAREA_FAILURE,
  payload: errorMessage,
});

export const clearGaugeareaUnmount = () => ({
  type: GaugeareaActionTypes.CLEAR_GAUGEAREA_UNMOUNT,
});
