import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import {
    Card,
    CardHeader,
    CardActionArea,
    Tooltip,
} from '@mui/material';

import { selectLatestImagesByCamera } from '../../redux/picture/picture.selectors';
import { selectCameraItem } from '../../redux/camera/camera.selectors';

import { ImageUnavailableContainer } from './camera-all-card.styles';
import { AuthorizedS3Image } from '../s3-authorized/authorized-s3-image.component';

const CameraAllCard = ({ cameraId }) => {
    const latestImageData = useSelector(
        selectLatestImagesByCamera(cameraId),
    );
    const cameraItem = useSelector(selectCameraItem(cameraId));
    return (
        <Card>
            <Tooltip
                title={`${cameraItem.name}  ${cameraItem.river}`}
                placement="top"
                arrow
            >
                <div>
                    <CardHeader
                        sx={{ height: 50, whiteSpace: 'nowrap' }}
                        title={`${cameraItem.name}  ${cameraItem.river}`}
                        titleTypographyProps={{ variant: 'body1' }}
                        subheader={`${
              latestImageData
                  ? moment(latestImageData.taken_at).format(
                      'YYYY/MM/DD HH:mm   現在',
                  )
                  : 'N/A'
            }`}
                    />
                </div>
            </Tooltip>
            <CardActionArea
                component={Link}
                to={`../camera-list/${cameraItem.id}`}
                sx={{aspectRatio: '16/9'}}
            >
                {cameraItem.camera_status === '0' && latestImageData ? (
                    <AuthorizedS3Image
                        imageKey={latestImageData.src}
                        altInfo={latestImageData.taken_at}
                    />
                ) : (
                    <ImageUnavailableContainer>
                        {cameraItem.camera_status === '1'
                            ? '故障中'
                            : 'メンテナンス中'}
                    </ImageUnavailableContainer>
                )}
            </CardActionArea>
        </Card>
    );
};

export default CameraAllCard;
