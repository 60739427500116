import { all, call } from 'redux-saga/effects';

import { rootCameraSaga } from './camera/camera.sagas';
import { rootUserSaga } from './user/user.sagas';
import { rootPictureSaga } from './picture/picture.sagas';
import { rootWaterlevelSaga } from './waterlevel/waterlevel.sagas';
import { rootWeatherSaga } from './weather/weather.sagas';
import { rootGaugeareaSaga } from './gaugearea/gaugearea.sagas';
import { rootWeatherareasSaga } from './weatherareas/weatherareasSlice';

export default function* rootSaga() {
  yield all([
    call(rootCameraSaga),
    call(rootUserSaga),
    call(rootPictureSaga),
    call(rootWaterlevelSaga),
    call(rootWeatherSaga),
    call(rootGaugeareaSaga),    
    call(rootWeatherareasSaga),
  ]);
}
