import styled from 'styled-components';

export const TitleContainer = styled.div`
  width: 100%;
  max-width: ${(props) =>
    props.titleFor === 'camera-detail' ? '100%' : '250px'};
  height: min-content;
  opacity: ${(props) => (props.isUserLoading ? 0.6 : 1)};
  padding: 6px 10px;
  font-size: 17px;
  font-weight: bold;
  border: 2px solid #3bb6a9;
  background-color: #fef9e7;
  display: inline-block;
  border-radius: 5px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12),
    0 1px 4px rgba(0, 0, 0, 0.24);
`;
