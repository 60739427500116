import { API, Auth } from 'aws-amplify'; 

const mergeByProperty = (target, source, prop) => {
    source.forEach(sourceElement => {
        let targetElement = target.find(targetElement => {
            return sourceElement[prop] === targetElement[prop];
        })
        targetElement ?
            Object.assign(targetElement, sourceElement)
            :
            target.push(sourceElement);
    })
}

export async function getAllUsers() {
    const apiName = 'AdminQueries';
    const path = '/listUsers';
    try {
        const userSession = await Auth.currentSession();
        const userToken = await userSession
            .getAccessToken()
            .getJwtToken();
        const myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: userToken,
            },
        };
        const userList = await API.get(apiName, path, myInit);
        return userList;
    } catch (error) {
        console.error(error)
    }
}

export async function getUsersInGroup(groupname) {
    const apiName = 'AdminQueries';
    const path = `/listUsersInGroup?groupname=${groupname}`;
    try {
        const userSession = await Auth.currentSession();
        const userToken = await userSession
            .getAccessToken()
            .getJwtToken();
        const myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: userToken,
            },
        };
        const userListInGroup = await API.get(apiName, path, myInit);
        return userListInGroup 
    } catch (error) {
        console.error(error)
    }
}

export async function getCurrentUserMunicipalitiesUserList() {
    // If no municipality groups for admin get all users
    const apiName = 'AdminQueries';

    try {
        const userSession = await Auth.currentSession();
        const userToken = await userSession
            .getAccessToken();
        const userJwtToken = await userToken
            .getJwtToken();
        const currentUserGroups = userToken
            .payload['cognito:groups'];
        const currentUserMunicipalityGroups = currentUserGroups
            .filter(groupname => !isNaN(Number(groupname)));
        const myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: userJwtToken,
            },
        };

        // Exit on only Admin with all users
        if (currentUserMunicipalityGroups.length === 0) {
            return (await API.get(apiName,  '/listUsers', myInit)).Users;
        }

        let completeUserList = [];
        for (let municipalityGroupName of currentUserMunicipalityGroups) {
            const path = `/listUsersInGroup?groupname=${municipalityGroupName}`;
            const userListInGroup = await API.get(apiName, path, myInit);
            mergeByProperty(completeUserList, userListInGroup.Users, 'Username');
        }

        return completeUserList; 
    } catch (error) {
        console.error(error)
    }
}
