import UserActionTypes from './user.types';

export const emailSignInStart = (emailAndPassword) => ({
  type: UserActionTypes.EMAIL_SIGN_IN_START,
  payload: emailAndPassword,
});

export const signInSuccess = (userObj) => ({
  type: UserActionTypes.SIGN_IN_SUCCESS,
  payload: userObj,
});

export const signInFailure = (error) => ({
  type: UserActionTypes.SIGN_IN_FAILURE,
  payload: error,
});

export const checkUserSession = () => ({
  type: UserActionTypes.CHECK_USER_SESSION,
});

export const checkUserSessionComplete = (userObj) => ({
  type: UserActionTypes.CHECK_USER_SESSION_COMPLETE,
  payload: userObj,
});

export const checkUserSessionFailure = (error) => ({
  type: UserActionTypes.CHECK_USER_SESSION_FAILURE,
  payload: error,
});

export const signOutStart = () => ({
  type: UserActionTypes.SIGN_OUT_START,
});

export const signOutSuccess = () => ({
  type: UserActionTypes.SIGN_OUT_SUCCESS,
});

export const signOutFailure = (error) => ({
  type: UserActionTypes.SIGN_OUT_FAILURE,
  payload: error,
});

export const signUpStart = (userInput) => ({
  type: UserActionTypes.SIGN_UP_START,
  payload: userInput,
});

export const signUpSuccess = (user) => ({
  type: UserActionTypes.SIGN_UP_SUCCESS,
  payload: user,
});

export const signUpFailure = (error) => ({
  type: UserActionTypes.SIGN_UP_FAILURE,
  payload: error,
});

export const changePassStart = (oldAndNewPass) => ({
  type: UserActionTypes.CHANGE_PASS_START,
  payload: oldAndNewPass,
});

export const changePassSuccess = () => ({
  type: UserActionTypes.CHANGE_PASS_SUCCESS,
});

export const changePassFailure = (error) => ({
  type: UserActionTypes.CHANGE_PASS_FAILURE,
  payload: error,
});

export const setErrorMessage = (error) => ({
  type: UserActionTypes.SET_ERROR_MESSAGE,
  payload: error,
});

export const fetchAllUserStart = () => ({
  type: UserActionTypes.FETCH_ALL_USER_START,
});

export const fetchAllUserSuccess = (userList) => ({
  type: UserActionTypes.FETCH_ALL_USER_SUCCESS,
  payload: userList,
});

export const fetchAllUserFailure = (error) => ({
  type: UserActionTypes.FETCH_ALL_USER_FAILURE,
  payload: error,
});

export const deleteUserStart = (username) => ({
  type: UserActionTypes.DELETE_USER_START,
  payload: username,
});

export const deleteUserSuccess = () => ({
  type: UserActionTypes.DELETE_USER_SUCCESS,
});

export const deleteUserFailure = (error) => ({
  type: UserActionTypes.DELETE_USER_FAILURE,
  payload: error,
});
