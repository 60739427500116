import { useState, useEffect } from "react";
import moment from "moment";
import { toast } from "react-hot-toast";

import CustomButton from "../../components/custom-button/custom-button.component";

import { 
    getWaterlevel,
    getWeather,
    getWeatherArea
} from "../../api.js";

export const CSVExportButton = ({camera}) => {
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        if (camera === undefined)
            setIsDisabled(true);
    }, [camera]);

    const exportCSV = async () => {

        setIsDisabled(true);
        const loadingToastId = toast.loading(
            'CSVファイルを出力中です。しばらくお待ちください。'
        );

        let waterlevelData, weatherData, weatherareasData;

        if (camera.weatherarea === null || camera.weatherarea === undefined) {
            waterlevelData = await getWaterlevel(camera.id);
            weatherData = [];
            weatherareasData = {};
        } else {
            [
                waterlevelData,
                weatherData,
                weatherareasData
            ] = await Promise.all([
                getWaterlevel(camera.id), 
                getWeather(camera.weatherarea),
                getWeatherArea(camera.weatherarea)
            ]);
        }

        if (
            [waterlevelData, weatherData, weatherareasData]
                .includes(undefined)
        ) {
            toast.dismiss(loadingToastId);
            setIsDisabled(false);
        }

        if (
            (
                !(waterlevelData instanceof Array) ||
                waterlevelData.length === 0
            ) && 
            (
                !(weatherData instanceof Array) || 
                weatherData.length === 0 
            )
        ) {
            toast.dismiss(loadingToastId);
            toast.success("出力するデータはありません。");
            setIsDisabled(false);
            return;
        }

        let CSVData = {};
        const timeFormat = 'YYYYMMDD-HHmm';
        for (const waterlevel of waterlevelData) {
            if (typeof waterlevel.level_date !== 'string')
                continue;
            CSVData[waterlevel.level_date] = {
                cameraID: camera.id,
                timestamp: moment(waterlevel.level_date).format(timeFormat),
                level_judge: waterlevel.level_judge,
                level_pred: waterlevel.level_pred,
                level_status: waterlevel.level_status,
                weath_lat: weatherareasData.weath_lat,
                weath_lon: weatherareasData.weath_lon,
                rainfall: undefined,
            };
        }

        for (const weather of weatherData) {
            if (typeof weather.condition_date !== 'string')
                continue;
            if (CSVData[weather.condition_date] === undefined) {
                CSVData[weather.condition_date] = {
                    cameraID: camera.id,
                    timestamp: moment(weather.condition_date)
                        .format(timeFormat),
                    level_judge: undefined,
                    level_pred: undefined,
                    level_status: undefined,
                    weath_lat: weatherareasData.weath_lat,
                    weath_lon: weatherareasData.weath_lon,
                    rainfall: weather.rainfall,
                };
            } else {
                CSVData[weather.condition_date]['rainfall'] = weather.rainfall;
            }
        }

        CSVData = Object.values(CSVData);
        CSVData.sort(
            (a, b) => {
                return moment(a.timestamp, timeFormat)
                        .isAfter(moment(b.timestamp, timeFormat));
            }
        );

        let CSVString = 'id,' + Object.keys(CSVData[0]).join(',') + '\n';

        CSVString += CSVData.reduce((curr, next, i) => {
            return curr + `${i+1},` + Object.values(next)
                .reduce((curr, next) => {
                    return curr + (next === undefined || next === null ?
                        ',' : next + ',');
                }, '') + '\n';
        }, '');

        const CSVBlob = new Blob([CSVString], {type: 'text/csv'});
        const URL = window.URL.createObjectURL(CSVBlob);

        const link = document.createElement('a');
        link.setAttribute('href', URL);
        link.setAttribute(
            'download', 
            `${camera.name}_${CSVData[0].timestamp}.csv`
        );
        link.click();

        setIsDisabled(false);
        toast.dismiss(loadingToastId);
        toast.success('CSVファイルを出力しました。');
    };

    return (
        <CustomButton
            type="button"
            onClick={exportCSV}
            disabled={isDisabled}
        >
            CSV出力
        </CustomButton> 
    );
}
