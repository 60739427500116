import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import TitleLabel from '../../components/title-label/title-label.component';
import CameraMapHOC from '../../components/camera-map/camera-map.container';
import CameraTableHOC from '../../components/camera-table/camera-table.container';

import { fetchCameraStart } from '../../redux/camera/camera.actions';

import './camera-list.css';

const CameraListPage = ({ setValue }) => {
    const dispatch = useDispatch();
    const [currPage, setCurrPage] = useState(0);

    useEffect(() => {
        setValue(1);
    }, [setValue]);

    useEffect(() => {
        let timer;
        dispatch(fetchCameraStart('CAMERA-LIST'));
        timer = setInterval(() => {
            dispatch(fetchCameraStart('CAMERA-LIST'));
        }, 300000);
        return () => {
            clearInterval(timer);
        };
    }, [dispatch]);


    return (
        <div className="cameraListPage">
            <TitleLabel 
                title={'河川監視カメラ設置箇所'}
                style={{gridArea: 'map-title'}}
            />
            <CameraMapHOC {...{ currPage }} />
            <CameraTableHOC {...{ currPage, setCurrPage }} />
        </div>
    );
};

export default CameraListPage;
