import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { fetchWrapperWithCognitoToken } from "../utils/utils";

const weatherareasAdapter = createEntityAdapter();
const initialState = weatherareasAdapter.getInitialState({
    status: 'idle',
    error: ''
});

export const weatherareasSlice = createSlice({
    name: 'weatherareas',
    initialState,
    reducers: {
        loading: (state, action) => {
            state.status = 'loading';
        },
        succeeded: (state, action) => {
            state.status = 'succeeded';
            weatherareasAdapter.upsertMany(state, action.payload);
        },
        nodata: (state, action) => {
            state.status = 'nodata';
        },
        failed: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        unmount: (state, action) => {
            state.status = 'idle';
            weatherareasAdapter.removeAll();
        }
    }
});

export const {
    loading: weatherareasLoading,
    succeeded: weatherareasSucceeded,
    nodata: weatheraresNodata,
    failed: weatheraresFailed,
    unmount: weatheraresUnmount,
} = weatherareasSlice.actions;

export default weatherareasSlice.reducer;

export const {
    selectAll: selectAllWeatherareas,
    selectById: selectWeatherareaById,
    selectIds: selectWeatherareaIds
} = weatherareasAdapter.getSelectors(state => state.weatherareas);

function* fetchWeatherareas(action) {
    try {
        const weatherareasAPIURL = new URL(`https://${process.env.REACT_APP_API_DOMAIN}/infratector/api/weatherarea/?`);
        if (typeof action.payload === 'object')
            for (let [name, condition] of Object.entries(action.payload)) 
                if (condition) 
                    weatherareasAPIURL.searchParams.set(name, condition);

        const APIResponse = yield call(
            fetchWrapperWithCognitoToken,
            weatherareasAPIURL
        );
        const responseJSON = yield APIResponse.json();
        const weatherareas = responseJSON.results;

        if (weatherareas.length === 0) {
            yield put(weatheraresNodata());
        }

        yield put(weatherareasSucceeded(weatherareas));
    } catch(error) {
        yield put(weatheraresFailed(error.message));
    }
}

function* watchWeatherares() {
    yield takeLatest(weatherareasLoading, fetchWeatherareas);
}

export function* rootWeatherareasSaga() {
    yield all([call(watchWeatherares)]);
}
