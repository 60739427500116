export const writeJsonFile = (cameraItems) => {
  let formData = new FormData();
  formData.append('camerainfo', JSON.stringify(cameraItems));
  const requestOptions = {
    method: 'POST',
    body: formData,
  };
  fetch('http://localhost:3001/api/area/camerainfo', requestOptions);

  // var json = JSON.stringify(geoItems);
  // console.log(json);
  // var blob = new Blob([json], { type: 'application/json' });
  // var url = URL.createObjectURL(blob);
  // var a = document.createElement('a');
  // a.download = 'test.json';
  // a.href = url;
  // a.click();
};

export const oprStatusMap = {
  0: '稼働中',
  1: '故障中',
  2: 'メンテナンス中',
};
