import WeatherActionTypes from './weather.types';

export const fetchWeatherStart = (weatherareaId, timeArray) => ({
  type: WeatherActionTypes.FETCH_WEATHER_START,
  weatherareaId: weatherareaId,
  timeArray: timeArray,
});

export const fetchWeatherSuccess = (weatherList, timestamp) => ({
  type: WeatherActionTypes.FETCH_WEATHER_SUCCESS,
  weatherList: weatherList,
  timestamp: timestamp,
});

export const fetchWeatherFailure = (errorMessage) => ({
  type: WeatherActionTypes.FETCH_WEATHER_FAILURE,
  payload: errorMessage,
});

export const clearWeatherUnmount = () => ({
  type: WeatherActionTypes.CLEAR_WEATHER_UNMOUNT,
});
