import GaugeareaActionTypes from './gaugearea.types';

const INITIAL_STATE = {
  isFetching: false,
  errorMessage: null,
  fetchTimestamp: null,
  gaugeareaList: [],
};

const gaugeareaReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GaugeareaActionTypes.CLEAR_GAUGEAREA_UNMOUNT:
      return {
        ...state,
        gaugeareaList: [],
      };
    case GaugeareaActionTypes.FETCH_GAUGEAREA_START:
      return {
        ...state,
        isFetching: true,
      };
    case GaugeareaActionTypes.FETCH_GAUGEAREA_SUCCESS:
      return {
        ...state,
        gaugeareaList: action.gaugeareaList,
        fetchTimestamp: action.timestamp,
        isFetching: false,
      };
    case GaugeareaActionTypes.FETCH_GAUGEAREA_FAILURE:
      return {
        ...state,
        isFetching: false,
        fetchTimestamp: null,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default gaugeareaReducer;
