import React from 'react';
import { Link } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import { useSelector } from 'react-redux';

import { selectCameraNameById } from '../../redux/camera/camera.selectors';

const NavBar = ({
  currentUser,
  cameraIdList,
  value,
  handleChange,
}) => {
  const cameraName = useSelector(
    selectCameraNameById(cameraIdList[0]),
  );
  const pageNavMap = [
    ['画像一覧', '/camera-all'],
    ['カメラ位置', '/camera-list'],
    [
      'カメラ詳細',
      cameraIdList.length
        ? `/camera-list/${cameraIdList[0]}`
        : `/camera-list/-`,
    ],
    ['カメラ設定', '/camera-setting'],
  ];
  return currentUser ? (
    <Box
      sx={{
        backgroundColor: '#fef9e7',
        boxShadow: 3,
        // boxShadow: '0 6px 7px rgba(0, 0, 0, 0.22)',
        display: 'flex',
        justifyContent: 'space-between',
        height: '47px',
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{
          sx: {
            backgroundColor: '#3bb6a9',
            borderRadius: 1,
            // border: 1,
            height: '5px',
          },
        }}
        sx={{
          borderRadius: 1,
          width: '100%',
          '&& .Mui-selected': {
            color: 'black',
            fontWeight: 'bold',
          },
          '& div.MuiTabs-scroller': {
            '& .MuiTabs-flexContainer': {
              // display: 'flex',
              // alignItems: 'center',
              // justifyContent: 'space-around',
            },
          },
        }}
        centered
      >
        {
          pageNavMap.map((item, idx) => {
            return (
              <Tab
                key={item[1]}
                id={idx}
                sx={{ fontSize: 20 }}
                label={`${item[0]}`}
                component={Link}
                to={`${item[1]}`}
              />
            );
          })
        }
      </Tabs>
    </Box>
  ) : null;
};

export default NavBar;
