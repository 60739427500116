import { createSelector } from 'reselect';

const selectWaterlevel = (state) => state.waterlevel;

export const selectAllWaterlevel = createSelector(
  [selectWaterlevel],
  (waterlevel) => waterlevel.waterlevelList,
);

// the first index after the last realWaterlv index is the first predWaterlv index
export const selectFirstIndexPred = createSelector(
  [selectAllWaterlevel],
  (waterlevel) => {
    if (waterlevel.length) {
      const index = waterlevel
        .map((water) => water.level_status)
        .lastIndexOf('20');
      return index + 1;
    }
  },
);

export const selectAllLatestWaterLevel = createSelector(
  [selectWaterlevel],
  (waterlevel) => waterlevel.latestWaterlevel,
);

export const selectLatestWaterLevel = (cameraIdParam) =>
  createSelector([selectWaterlevel], (waterlevel) =>
    waterlevel.latestWaterlevel[cameraIdParam]
      ? waterlevel.latestWaterlevel[cameraIdParam]
      : [],
  );

export const selectIsWaterlevelFetching = createSelector(
  [selectWaterlevel],
  (waterlevel) => waterlevel.isFetching,
);

export const selectFetchTimestamp = createSelector(
  [selectWaterlevel],
  (waterlevel) =>
    waterlevel.fetchTimestamp ? waterlevel.fetchTimestamp : '-',
);

export const selectLatestFetchTimestamp = createSelector(
  [selectWaterlevel],
  (waterlevel) =>
    waterlevel.fetchTimestampLatest
      ? waterlevel.fetchTimestampLatest
      : '-',
);

export const selectIsWaterlevelLoaded = createSelector(
  [selectAllWaterlevel],
  (waterlevel) => {
    return waterlevel.length !== 0 ? true : false;
  },
);
