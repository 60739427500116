import { Auth } from 'aws-amplify';
import {
    takeLatest,
    call,
    put,
    all,
    select,
} from 'redux-saga/effects';
import moment from 'moment';
import toast from 'react-hot-toast';

import CameraActionTypes from './camera.types';

import {
    fetchCameraStart,
    fetchCameraSuccess,
    fetchCameraFailure,
    modifyCameraSuccess,
    modifyCameraFailure,
    removeCameraSuccess,
    removeCameraFailure,
    createCameraSuccess,
    createCameraFailure,
} from './camera.actions';

import { fetchPictureStart } from '../picture/picture.actions';
import { fetchLatestWaterlevelStart } from '../waterlevel/waterlevel.actions';

import { selectIdList } from './camera.selectors';

import { fetchWrapperWithCognitoToken } from '../utils/utils';

const fetchCameraAllPage = async (offset) => {
    const cameraData = await fetchWrapperWithCognitoToken(
        `https://${process.env.REACT_APP_API_DOMAIN}/infratector/api/cameras/?limit=8&offset=${
            (offset - 1) * 8
        }`,
    );
    if (cameraData.status >= 500 && cameraData.status < 600)
        throw new Error('RDS接続エラー発生。');
    return await cameraData.json();
};

const fetchCameraDetailPage = async () => {
    const cameraData = await fetchWrapperWithCognitoToken(
        `https://${process.env.REACT_APP_API_DOMAIN}/infratector/api/cameras/?limit=10000000000`,
    );
    if (cameraData.status >= 500 && cameraData.status < 600)
        throw new Error('RDS接続エラー発生。');
    return await cameraData.json();
};

const fetchCameraListPage = async (offset) => {
    const cameraData = await fetchWrapperWithCognitoToken(
        `https://${process.env.REACT_APP_API_DOMAIN}/infratector/api/cameras/?limit=100000`,
    );
    if (cameraData.status >= 500 && cameraData.status < 600)
        throw new Error('RDS接続エラー発生。');
    return await cameraData.json();
};

export function* fetchCameraAsync({ whichPage, offset }) {
    // toast.dismiss();
    const toastId = toast.loading(`カメラデータロード中...`, {
        duration: 4000,
    });
    try {
        // only for CAMERA-ALL page, start fetching latest picture 1 by 1 for each camera
        if (whichPage === 'CAMERA-ALL') {
            const cameraDataParsed = yield call(fetchCameraAllPage, offset);
            yield put(
                fetchCameraSuccess(
                    cameraDataParsed.results,
                    Math.ceil(cameraDataParsed.count / 8),
                ),
            );
            yield toast.success(`カメラデータロード成功`, { id: toastId });
            const cameraItems = yield select(selectIdList);
            yield put(fetchPictureStart(cameraItems, whichPage));
        } else if (whichPage === 'CAMERA-DETAIL') {
            let cameraDataParsed = yield call(fetchCameraDetailPage);
            cameraDataParsed = yield cameraDataParsed.results.filter(
                (camera) => camera.name !== '',
            );
            yield put(fetchCameraSuccess(cameraDataParsed));
            yield toast.success(`カメラデータロード成功`, { id: toastId });
        } else if (whichPage === 'CAMERA-LIST') {
            const cameraDataParsed = yield call(fetchCameraListPage);
            yield put(fetchCameraSuccess(cameraDataParsed.results));
            yield toast.success(`カメラデータロード成功`, { id: toastId });
            const cameraItems = yield select(selectIdList);
            yield put(
                fetchLatestWaterlevelStart(cameraItems, [
                    moment().subtract(18, 'hours').format('YYYY-MM-DDTHH:mm'),
                    moment().add(6, 'hours').format('YYYY-MM-DDTHH:mm'),
                ]),
            );
        } else if (whichPage === 'CAMERA-SETTING') {
            const cameraDataParsed = yield call(fetchCameraListPage);
            yield put(fetchCameraSuccess(cameraDataParsed.results));
            yield toast.success(`カメラデータロード成功`, { id: toastId });
        }
    } catch (error) {
        yield put(fetchCameraFailure(error.message));
        yield toast.error(`カメラデータロード失敗`, {
            id: toastId,
        });
    }
}

export function* fetchCameraStartSaga() {
    yield takeLatest(
        CameraActionTypes.FETCH_CAMERA_START,
        fetchCameraAsync,
    );
}

function* putCameraAsync({ currCameraItem }) {
    const extractedData = yield (({
        id,
        imei,
        name,
        river,
        place,
        lat,
        lon,
        camera_status,
        level_thresh1,
        level_thresh2,
        youtube_url,
        em_url,
        group,
    }) => ({
        id,
        imei,
        name,
        river,
        place,
        lat,
        lon,
        camera_status,
        level_thresh1,
        level_thresh2,
        youtube_url,
        em_url,
        group,
    }))(currCameraItem);
    toast.dismiss();
    const toastId = toast.loading(`カメラ登録中...`);
    try {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(extractedData),
        };
        const response = yield call(
            fetchWrapperWithCognitoToken,
            `https://${process.env.REACT_APP_API_DOMAIN}/infratector/api/cameras/${extractedData.id}/`,
            requestOptions,
        );
        if (response.status === 200) {
            // const responseParsed = yield response.json();
            yield toast.success(`カメラ情報を登録しました。`, {
                id: toastId,
            });
            yield put(modifyCameraSuccess());
            yield put(fetchCameraStart('CAMERA-SETTING'));
        } else {
            const responseParsed = yield response.json();
            if (Array.isArray(responseParsed.imei)) {
                if (
                    responseParsed.imei.includes(
                        'この IMEI を持った カメラ が既に存在します。'
                    )
                ) {
                    yield toast(
                        '指定したカメラIDは既に使用されています。',
                        {id: toastId, icon: '⚠️'}
                    );
                }
            } else {
                yield toast.error(
                    `カメラ登録失敗: ${JSON.stringify(responseParsed)}`,
                    {
                        id: toastId,
                    },
                );
            }

            yield put(modifyCameraFailure());
        }
    } catch (error) {
        yield toast.error(`カメラ登録失敗: ${error.message}`, {
            id: toastId,
        });
        yield put(modifyCameraFailure(error.message));
    }
}

function* postCameraAsync({ currCameraItem }) {
    const extractedData = yield (({
        imei,
        name,
        river,
        place,
        lat,
        lon,
        camera_status,
        level_thresh1,
        level_thresh2,
        youtube_url,
        em_url,
        group,
    }) => ({
        imei,
        name,
        river,
        place,
        lat,
        lon,
        camera_status,
        level_thresh1,
        level_thresh2,
        youtube_url,
        em_url,
        group,
    }))(currCameraItem);
    yield (extractedData['gauge_mode'] = '10');
    yield (extractedData['battery_status'] = '100');
    if (!extractedData['group']) {
        const currSession = yield Auth.currentSession();
        const municipalityGroup = currSession.idToken.payload["cognito:groups"]
            .filter(groupname => !isNaN(Number(groupname)));
        if (municipalityGroup.length) 
            extractedData['group'] = municipalityGroup[0];
    }
    toast.dismiss();
    const toastId = toast.loading(`カメラ新登録中...`);
    try {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(extractedData),
        };
        const response = yield call(
            fetchWrapperWithCognitoToken,
            `https://${process.env.REACT_APP_API_DOMAIN}/infratector/api/cameras/`,
            requestOptions,
        );
        if (response.status === 201) {
            yield toast.success(`カメラ情報を登録しました。`, {
                id: toastId,
            });
            yield put(createCameraSuccess());
            yield put(fetchCameraStart('CAMERA-SETTING'));
        } else {
            const responseParsed = yield response.json();
            if (Array.isArray(responseParsed.imei)) {
                if (
                    responseParsed.imei.includes(
                        'この IMEI を持った カメラ が既に存在します。'
                    )
                ) {
                    yield toast(
                        '指定したカメラIDは既に使用されています。',
                        {id: toastId, icon: '⚠️'}
                    );
                }
            } else {
                yield toast.error(
                    `カメラ新登録失敗: ${JSON.stringify(responseParsed)}`,
                    {
                        id: toastId,
                    },
                );
            }
            yield put(createCameraFailure());
        }
    } catch (error) {
        yield toast.error(`カメラ新登録失敗: ${error.message}`, {
            id: toastId,
        });
        yield put(createCameraFailure(error.message));
    }
}

function* deleteCameraAsync({ currCameraItem }) {
    toast.dismiss();
    const toastId = toast.loading(`カメラ削除中...`);
    try {
        const requestOptions = {
            method: 'DELETE',
        };
        const response = yield call(
            fetchWrapperWithCognitoToken,
            `https://${process.env.REACT_APP_API_DOMAIN}/infratector/api/cameras/${currCameraItem.id}/`,
            requestOptions,
        );
        if (response.status === 204) {
            yield toast.success(`カメラ情報を削除しました。`, {
                id: toastId,
            });
            yield put(removeCameraSuccess());
            yield put(fetchCameraStart('CAMERA-SETTING'));
        } else {
            const responseParsed = yield response.json();
            yield toast.error(
                `カメラ削除失敗${currCameraItem.name}: ${JSON.stringify(
                    responseParsed,
                )}`,
                {
                    id: toastId,
                },
            );
            yield put(removeCameraFailure());
        }
    } catch (error) {
        yield toast.error(`カメラ削除失敗: ${error.message}`, {
            id: toastId,
        });
        yield put(removeCameraFailure(error.message));
    }
}

export function* modifyCameraStart() {
    yield takeLatest(
        CameraActionTypes.MODIFY_CAMERA_START,
        putCameraAsync,
    );
}

export function* createCameraStart() {
    yield takeLatest(
        CameraActionTypes.CREATE_CAMERA_START,
        postCameraAsync,
    );
}

export function* removeCameraStart() {
    yield takeLatest(
        CameraActionTypes.REMOVE_CAMERA_START,
        deleteCameraAsync,
    );
}

export function* rootCameraSaga() {
    yield all([
        call(fetchCameraStartSaga),
        call(modifyCameraStart),
        call(createCameraStart),
        call(removeCameraStart),
    ]);
}
