import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@mui/material';

const ModifyDialogComponent = ({
  open,
  handleClose,
  isUserInput,
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        id="alert-dialog-title"
        sx={{ whiteSpace: 'break-spaces' }}
      >
        {isUserInput
          ? 'このカメラ名は既に登録されています。\n既存のカメラ情報を更新しますか？'
          : 'カメラ情報を更新します。\nよろしいですか？'}
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} color="primary" value="no">
          いいえ
        </Button>
        <Button
          onClick={handleClose}
          color="primary"
          value={'yes'}
          autoFocus
        >
          はい
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModifyDialogComponent;
