import WaterlevelActionTypes from './waterlevel.types';

const INITIAL_STATE = {
  isFetching: false,
  errorMessage: null,
  fetchTimestamp: null,
  waterlevelList: [],
  fetchTimestampLatest: null,
  latestWaterlevel: {},
};

const waterlevelReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WaterlevelActionTypes.CLEAR_WATERLEVEL_UNMOUNT:
      return {
        ...state,
        waterlevelList: [],
      };
    case WaterlevelActionTypes.FETCH_WATERLEVEL_START:
      return {
        ...state,
        isFetching: true,
      };
    case WaterlevelActionTypes.FETCH_WATERLEVEL_SUCCESS:
      return {
        ...state,
        waterlevelList: action.waterlevelList,
        fetchTimestamp: action.timestamp,
        isFetching: false,
      };
    case WaterlevelActionTypes.FETCH_LATEST_WATERLEVEL_SUCCESS:
      return {
        ...state,
        latestWaterlevel: action.latestWaterlevel,
        fetchTimestampLatest: action.timestamp,
        isFetching: false,
      };
    case WaterlevelActionTypes.FETCH_WATERLEVEL_FAILURE:
      return {
        ...state,
        isFetching: false,
        fetchTimestamp: null,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default waterlevelReducer;
