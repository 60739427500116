import React from 'react';

import { Autocomplete, TextField } from '@mui/material';

const CameraSelectEditable = ({
  allCameraItems,
  currCameraItem,
  handleChangeDropdown,
  handleChangeInputValue,
}) => {
  return (
    <Autocomplete
      id="camera-id-editable"
      onChange={handleChangeDropdown}
      value={currCameraItem}
      inputValue={currCameraItem.name}
      onInputChange={handleChangeInputValue}
      freeSolo
      selectOnFocus
      options={allCameraItems}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.name;
      }}
      sx={{
        width: '320px',
        height: '56px',
        backgroundColor: '#fef9e7',
        left: '30px',
        borderRadius: '4px',
      }}
      renderInput={(params) => (
        <TextField {...params} label="カメラ名作成・選択" />
      )}
    />
  );
};

export default CameraSelectEditable;
