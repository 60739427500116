import React, { useRef } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import toast from 'react-hot-toast';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

import {
  DatePickerWrapperStyles,
  DateRangePickerContainer,
  CustomButtonContainer,
} from './datetime-picker.styles';

import CustomButton from '../../components/custom-button/custom-button.component';
import { useSelector } from 'react-redux';

const DateTime = ({ dateRange, setDateRange, selectIsDataLoaded }) => {
  const currDate = new Date();
  const dateRef = useRef();
  const isDataLoaded = useSelector(selectIsDataLoaded);

  // handle when user presses "過去情報表示" button
  const handleApply = (event, picker) => {
    picker.element.val(
      picker.startDate.format('YYYY年MM月DD日 HH:mm') +
        ' - ' +
        picker.endDate.format('YYYY年MM月DD日 HH:mm'),
    );
    toast.success('過去データモード');
    setDateRange([
      picker.startDate.format('YYYY-MM-DDTHH:mm'),
      picker.endDate.format('YYYY-MM-DDTHH:mm'),
    ]);
  };

  return (
    <>
      <DateRangePickerContainer>
        <DateRangePicker
          ref={dateRef}
          initialSettings={{
            autoUpdateInput: false,
            timePicker: true,
            timePickerIncrement: 10,
            maxSpan: { days: 2 },
            locale: {
              format: 'YYYY年MM月DD日 h:mm a',
              applyLabel: '過去情報表示',
              cancelLabel: 'キャンセル',
              daysOfWeek: ['日', '月', '火', '水', '木', '金', '土'],
              monthNames: [
                '1月', '2月', '3月', '4月', '5月', '6月',
                '7月', '8月', '9月', '10月', '11月', '12月',
              ],
            },
            minDate: new Date(
              new Date().setMonth(currDate.getMonth() - 13),
            ),
            maxDate: new Date(),
          }}
          onApply={handleApply}
        >
          <input
            type="text"
            className="date_picker"
            placeholder="ここをクリック"
          />
        </DateRangePicker>
          {dateRange[0] !== null && dateRange[1] !== null && isDataLoaded === false ?
              <span style={{color: "red"}}>※指定した日時にデータはありません。</span>
            :
              ""
          }
      </DateRangePickerContainer>

      <CustomButtonContainer>
        <CustomButton
          type="button"
          onClick={() => {
            dateRef.current.ref.value = '';
            setDateRange([null, null]);
            toast.success('５分ごとにモード');
          }}
        >
          最新情報表示
        </CustomButton>
      </CustomButtonContainer>
      <DatePickerWrapperStyles />
    </>
  );
};

export default DateTime;
